import * as types from './../constants/actionType';

const initialState = {
    data: [],
    paginate: {},
    isLoading: true,
    message: null,
    isRequest: false,
    status: false,
    errors: {},
    customer: null
}

const Customer = (state = initialState, action) => {
    switch (action.type) {
        case types.CUSTOMER_REQUEST:
            return {
                ...state,
                isLoading: action.status
            }
        case types.GET_CUSTOMER_LIST:
            return {
                ...state,
                data: [...action.data.data],
                paginate: { ...action.data.paginate },
                isLoading: false
            };
        case types.GET_DETAIL_CUSTOMER:
            if (action.status) {
                return {
                    ...state,
                    message: null,
                    customer: action.data,
                };
            } else {
                return {
                    ...state,
                    status: false,
                    message: action.data,
                };
            }
        case types.RESET_CUSTOMER:
            return {
                ...state,
                isRequest: false,
                status: false,
                errors: {},
                customer: null
            };
        case types.CREATE_CUSTOMER:
            if (action.status) {
                return {
                    ...state,
                    status: true,
                    message: 'Tạo Khách hàng thành công',
                    errors: {},
                };
            } else {
                return {
                    ...state,
                    status: false,
                    message: 'Vui lòng kiểm tra lại những lỗi bên dưới',
                    errors: action.data,
                };
            }
        case types.UPDATE_CUSTOMER:
            if (action.status) {
                return {
                    ...state,
                    status: true,
                    message: 'Cập nhật Khách hàng thành công',
                    errors: {},
                };
            } else {
                return {
                    ...state,
                    status: false,
                    message: 'Vui lòng kiểm tra lại những lỗi bên dưới',
                    errors: action.data,
                };
            }
        default:
            return { ...state };
    }
}

export default Customer;