import React, { Component } from "react";
import SaleTag from "./SaleTag";
import Table from "../../../components/common/Table";
import { getListProductSale } from "../../../actions/sale";
import Paginate from "../../../components/Paginate";
import { withRouter } from "react-router-dom";
import InputSearch from "../../../components/common/InputSearch";
import { PER_PAGE } from "../../../constants/Variable";

class SaleApartment extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.state = {
      data: [],
      paginate: {},
      page: 1,
      perPage: PER_PAGE,
      isLoading: true,
      tableHeaderApartment: [
        {
          label: "STT",
          index: "stt",
          callback: null,
        },
        {
          label: "MÃ CĂN",
          index: "product_code",
          callback: null,
        },
        {
          label: "TRẠNG THÁI",
          index: "status",
          callback: null,
        },
        {
          label: "TOTAL VIEWS",
          index: "total_view",
          callback: null,
        },
      ],
    };
  }

  componentDidMount() {
    let { id } = this.props.match.params;
    let { page, perPage } = this.state;
    getListProductSale("", {
      saler_id: id,
      page: page,
      per_page: perPage,
      search_text: this.search,
    })
      .then((res) => {
        this.setState({
          data: res.data.data,
          paginate: res.data.paginate,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isLoading: false,
        });
      });
  }

  changePage = (pageNumber, e) => {
    let { page, perPage } = this.state;
    if (pageNumber !== page) {
      let { id } = this.props.match.params;
      getListProductSale("", {
        saler_id: id,
        search_text: this.search,
        page: pageNumber,
        per_page: perPage,
      })
        .then((res) => {
          this.setState({
            data: res.data.data,
            paginate: res.data.paginate,
          });
        })
        .catch((err) => {
          console.error(err);
        });

      this.setState({ page: pageNumber });
    }
  };

  onChangeSearch = (e) => {
    let { page, perPage } = this.state;
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : "";

    this.search = {
      ...this.search,
      [name]: value,
    };

    let { id } = this.props.match.params;
    getListProductSale(id, this.search, {
      page: page,
      per_page: perPage,
    })
      .then((res) => {
        this.setState({
          data: res.data.data,
          paginate: res.data.paginate,
        });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    let { tableHeaderApartment, paginate, data, isLoading } = this.state;

    return (
      <>
        <div className="container-fluid container-shadow">
          <SaleTag />
          <div className="row">
            <div className="col-md-6 col-lg-4 mt-4">
              <InputSearch onChange={this.onChangeSearch} />
            </div>
          </div>
          <div className="row table-border">
            <div className="col-md-12 name-header-part sale-apartment-custom">
              <Table
                fixed={true}
                tableHeaders={tableHeaderApartment}
                tableData={data}
                tablePaginate={paginate}
                isLoading={isLoading}
              />
            </div>
            <div className="table-pagenatie ml-auto">
              <div className="paginate-wrapper">
                <Paginate paginate={paginate} changePage={this.changePage} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SaleApartment);
