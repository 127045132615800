import Swal from "sweetalert2";
import currencyFormatter from "currency-formatter";
import { NotificationManager } from "react-notifications";

export const stt = (idx, current_page, page_size) => {
  if (current_page && current_page > 0) {
    return (current_page - 1) * page_size + 1 + idx;
  }
  return idx + 1;
};

export const confirmDelete = () => {
  return Swal.fire({
    title: "Bạn có chắc chắn xóa?",
    text: "Thông tin một khi bị xóa sẽ không được phục hồi!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonText: "Có",
    cancelButtonText: "Không",
  });
};

export const afterRemove = (msg, status) => {
  return Swal.fire({
    text: msg,
    icon: status ? "success" : "error",
    showCancelButton: false,
    confirmButtonText: "Xác nhận",
  });
};

export const moneyFormat = (price) => {
  price = parseFloat(price);
  if (!isNaN(price)) {
    return currencyFormatter.format(price, {
      decimal: ",",
      precision: 0,
    });
  }

  return 0;
};

export const removeHistory = (object) => {
  let { location, history } = object.props;

  if (location.state && location.state.message) {
    object.message = location.state.message;
    history.replace({
      pathname: location.pathname,
      state: {},
    });
  }
};

export const confirmFinish = () => {
  return Swal.fire({
    title: "Hoàn thành dự án",
    icon: "success",
    showCancelButton: true,
    confirmButtonText: "Đồng ý",
    cancelButtonText: "Hủy",
  });
};

export const showMessage = (msg, status) => {
  return status
    ? NotificationManager.success(msg, "Thành công!", 4000)
    : NotificationManager.error(msg, "Lỗi!", 4000);
};

export const phoneFormat = (phone) => {
  if (phone)
    return (phone = phone
      .replace(/\D/g, "")
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        return `(${f}) ${s} ${t}`;
      }));
  return 0;
};

export const phoneFormatReplace = (phone) => {
  let val = phone;
  if (phone)
    return (phone = val
      .replace(/\D/g, "")
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        if (t) {
          return `${f}${s}${t}`;
        } else if (s) {
          return `${f}${s}`;
        } else if (f) {
          return `${f}`;
        }
      }));
  return 0;
};
export const convertMoney = (money) => {
  money = parseFloat(money);
  if (money >= 1000000000) {
    return (money / 1000000000).toFixed(2) + " TỶ";
  } else if (money >= 1000000) {
    const number = (money / 1000000).toFixed(2);
    if (number == money / 1000000) {
      return money / 1000000 + " TR"
    }
    return (money / 1000000).toFixed(2) + " TR";
  }

  return money;
};

export const phoneFormatCustom = (phone) => {
  if (phone) {
    if (phone.length === 11) {
      return (phone = phone
        .replace(/\D/g, "")
        .replace(/(\d{1,5})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
          return `(${f}) ${s} ${t}`;
        }));
    }
    return (phone = phone
      .replace(/\D/g, "")
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        return `(${f}) ${s} ${t}`;
      }));
  }
  return 0;
};
