import React, { Fragment, useEffect, useState } from 'react';
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { Form, Select, Button, Tag } from 'antd';
import { transformErrors } from '../../../utils';
import { useSales } from '../../../hooks/sale';
import { SALE_ASSIGN_STATUS } from '../../../constants';
import { useDevideCartProducts } from '../../../hooks/cart';
import { NotificationManager } from 'react-notifications';

const DevideCart = ({ t, products, onSuccess }) => {
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm();
  const { Option } = Select;
  const [{sales, saleLoading}, handleSale] = useSales();

  const closeDevide = () => {
    form.resetFields();
    setOpen(false);
  }

  const successCallback = (res) => {
    closeDevide();
    onSuccess();
    NotificationManager.success(t('messages.devide.message'), t('messages.devide.title'));
  }

  const errorCallback = (errors) => {
      let messages = transformErrors(errors, 'errors');
      form.setFields(messages);
  }

  const [{loading}, handleSubmit] = useDevideCartProducts(successCallback, errorCallback);
  
  const openDevide = () => {
    setOpen(true);
  }

  useEffect(() => {
    handleSale();
  }, []);

  const onFinish = (values) => {
    const productIds = products.map((o) => o.id);
    values = { ...values, product_id: productIds, status: SALE_ASSIGN_STATUS };
    handleSubmit(values)
  }

  return (
    <Fragment>
      <button className="btn btn-success btn-sm" onClick={openDevide}>
        <span className="lnr lnr-briefcase mr-1"></span> {t('buttons.devide')}
      </button>
      <Popup
        open={open}
        modal
        closeOnDocumentClick={false}
        className="custom-popup"
        onClose={closeDevide}
      >
        <div className="header">
          <h3 className="header-title">{t('product.devide')}</h3>
          <span className="close-btn" onClick={closeDevide}></span>
        </div>
        <div className="body">
          <Form form={form} layout='horizontal' onFinish={onFinish} className="justify-content-center">
            <Form.Item label={t('product.product_ids')} labelCol={{ span: 24 }}>
                <div>
                  {
                    products && products.map((o) => <Tag className="mb-2" color={o.color}>{o.product_code}</Tag>) 
                  }
                </div>
            </Form.Item>

            <Form.Item name="saler_id" label={t('product.saler')} labelCol={{ span: 24 }}>
              <Select
                showSearch
                style={{ width: '100%' }}
                loading={saleLoading}
                autoFocus={false}
                placeholder={t('product.saler')}
              >
                {
                  sales && sales.map((o) => <Option value={o.id}>{o.name}</Option>)
                }
              </Select>
            </Form.Item>
            
            <div className="btn-groups justify-content-end mt-4">
              <Button className="mr-3" danger type="primary" htmlType="button" disabled={loading} onClick={closeDevide}>
                {t('buttons.cancel')}
              </Button>
              <Button type="primary" htmlType="submit" loading={loading}>{t('buttons.submit')}</Button>
            </div>
          </Form>
        </div>
        </Popup>
    </Fragment>
  );
}

export default DevideCart;