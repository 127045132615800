import { combineReducers } from "redux";
import user from "./user";
import Customer from "./Customer";
import SaleReducer from "./sale";
import Favorite from "./Favorite";

const reducers = combineReducers({
  user,
  Customer,
  sale: SaleReducer,
  list_product_favorite: Favorite
});

export default reducers;
