import React, { Component } from 'react';
import { checkError, getError } from '../../helpers/error';
import { DatePicker } from 'antd';
import Alert from './Alert';

const InputDate = (props) => {
  let { errors, label, ...input } = props;
  return (
    <div className={checkError(errors, input.name)}>
      {label && <label className="input-label">{label}</label>}
      <DatePicker {...input} />
      <Alert messsage={getError(errors, input.name)} />
    </div>
  )
};

export default InputDate;
