export const SET_USER_DATA = "SET_USER_DATA";
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_LOADING_USER = "SET_LOADING_USER";
export const SET_USER = "SET_USER";
export const CHECK_USER = "CHECK_USER";
export const LOG_OUT = "LOG_OUT";

//distributor

export const DISTRIBUTOR_REQUEST = "DISTRIBUTOR_REQUEST";
export const GET_DISTRIBUTOR_LIST = "GET_DISTRIBUTOR_LIST";
export const RESET_DISTRIBUTOR = "RESET_DISTRIBUTOR";
export const CREATE_DISTRIBUTOR = "CREATE_DISTRIBUTOR";
export const GET_DISTRIBUTOR = "GET_DISTRIBUTOR";
export const EDIT_DISTRIBUTOR = "EDIT_DISTRIBUTOR";

//roles
export const ROLES_REQUEST = "ROLES_REQUEST";
export const GET_ROLES_LIST = "GET_ROLES_LIST";
export const RESET_ROLES = "RESET_ROLES";

export const PERMISSIONS_REQUEST = "ROLES_REQUEST";
export const GET_PERMISSIONS_LIST = "GET_PERMISSIONS_LIST";
export const CREATE_PERMISSIONS = "CREATE_PERMISSIONS";

// user
export const FORM_USER_REQUEST = "FORM_USER_REQUEST";
export const EDIT_USER = "EDIT_USER";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

//block_cate
export const BLOCK_CATE_REQUEST = "BLOCK_CATE_REQUEST";
export const RESET_BLOCK_CATE_BDS = "RESET_BLOCK_CATE_BDS";
export const GET_BLOCK_CATE_LIST = "GET_BLOCK_CATE_LIST";

//block
export const BLOCK_REQUEST = "BLOCK_REQUEST";
export const GET_BLOCK_LIST = "GET_BLOCK_LIST";
export const RESET_BLOCK_BDS = "RESET_BLOCK_BDS";

//building

export const BUILDING_REQUEST = "BUILDING_REQUEST";
export const GET_BUILDING_LIST = "GET_BUILDING_LIST";
export const RESET_BUILDING_BDS = "RESET_BUILDING_BDS";

//floor

export const FLOOR_REQUEST = "FLOOR_REQUEST";
export const GET_FLOOR_LIST = "GET_FLOOR_LIST";
export const RESET_FLOOR_BDS = "RESET_FLOOR_BDS";

export const GET_USER_LIST = "GET_USER_LIST";
export const RESET_USER = "RESET_USER";
export const USER_REQUEST = "USER_REQUEST";
export const CREATE_USER = "CREATE_USER";
export const GET_USER = "GET_USER";

// CUSTOMER
export const GET_CUSTOMER_LIST = "GET_CUSTOMER_LIST";
export const CUSTOMER_REQUEST = "CUSTOMER_REQUEST";
export const GET_DETAIL_CUSTOMER = "GET_DETAIL_CUSTOMER";
export const RESET_CUSTOMER = "RESET_CUSTOMER";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";

// SALES POLICY
export const GET_SALES_POLICY_LIST = "GET_SALES_POLICY_LIST";
export const SALES_POLICY_REQUEST = "SALES_POLICY_REQUEST";
export const GET_DETAIL_SALES_POLICY = "GET_DETAIL_SALES_POLICY";
export const RESET_SALES_POLICY = "RESET_SALES_POLICY";
export const CREATE_SALES_POLICY = "CREATE_SALES_POLICY";
export const UPDATE_SALES_POLICY = "UPDATE_SALES_POLICY";

// SALE
export const GET_SALE_LIST = "GET_SALE_LIST";
export const SALE_REQUEST = "SALE_REQUEST";

// Favorite
export const ADD_PRODUCT_FAVORITE = "ADD_PRODUCT_FAVORITE";
export const GET_ALL_FAVORITE = "GET_ALL_FAVORITE";
export const REMOVE_PRODUCT_FAVORITE = "REMOVE_PRODUCT_FAVORITE";
