import React, { Component } from "react";
// import RawTable from "./RawTable";
import { getListMediaRequest } from "../../../actions/media";
import Folder from "../../../assets/images/icons/folder.png";
// import NewFolder from "./NewFolder";
// import UploadFile from "./UploadFile";
import { sizeOf } from "../../../helpers/form";
import { MEDIA_FILE } from "../../../constants/Variable";
import FileIcon, { defaultStyles } from "react-file-icon";
import Breadcrumb from "./Breadcrumb";
import Skeleton from "react-loading-skeleton";
import Shared from "./Shared";
// import { useTranslation } from "react-i18next";
import { LinkOutlined, DownloadOutlined } from "@ant-design/icons";
// import InputSearch from "../../../components/common/InputSearch";
import axios from "axios";
import fileDownload from "js-file-download";
import { saveAs } from "file-saver";

import TableData from "../../../components/TableData";
class Media extends Component {
  constructor(props, context) {
    super(props, context);
    this.search = "";
    this.id = props.match.params.id;
    this.state = {
      id: props.match.params.id,
      data: [],
      folder: null,
      isLoading: true,
      openFilter: false,
      newFolder: false,
      uploadFile: false,
      share_id: null,
      FilesUploading: [],
    };
  }

  componentDidMount() {
    this.loadMedia(this.search, this.state.id);
  }

  // Prevent rerender many time
  static getDerivedStateFromProps = (props, state) => {
    if (props.match.params.id !== state.id) {
      return { id: props.match.params.id };
    } else return null;
  };
  
  componentDidUpdate(props, state) {
    if (this.state.id !== state.id) {
      this.loadMedia(this.search, this.state.id);
    }
  }
  // Prevent rerender many time
  handleDownload = (url, filename) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(res.data, filename);
      });

    // console.log(id)
  };

  loadMedia = (search, id) => {
    this.setState({ isLoading: true });
    getListMediaRequest(this.search, id)
      .then((res) => {
        this.setState({
          data: res.data.data,
          folder: res.data.folder,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isLoading: false });
      });
  };

  onChangeSearch = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;

    this.search = {
      ...this.search,
      [name]: value,
    };
    this.loadMedia();
  };

  openFilter = () => {
    let { openFilter } = this.state;
    this.setState({ openFilter: !openFilter });
  };

  // open new folder form
  newFolder = (e, status) => {
    e.preventDefault();
    this.setState({ newFolder: status });
  };

  onCompleteFolder = () => {
    this.loadMedia(this.search, this.state.id);
    this.setState({ newFolder: false });
  };

  // double click row
  popupCenter(url, title, w, h) {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const left = window.screen.width / 2 - w / 2 + dualScreenLeft;
    const top = 10;
    return window.open(
      url,
      title,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
        w +
        ", height=" +
        h +
        ", top=" +
        top +
        ", left=" +
        left
    );
  }

  onClickRow = (data) => {
    if (data) {
      if (data.model_type === MEDIA_FILE) {
        this.popupCenter(data.path, "popup", 800, 500);
      } else {
        this.props.history.push(`/manager/media/${data.id}`);
      }
    }
    return false;
  };

  // Edit folder
  onCompleteEditFolder = () => {
    this.loadMedia(this.search, this.state.id);
  };

  // Upload file
  onOpenUploadFile = (e) => {
    e.preventDefault();
    document.getElementById("upload_files").click();
  };

  onUploadFiles = (e) => {
    let { files } = e.target;
    if (files.length > 0) {
      this.setState(
        {
          uploadFile: true,
          FilesUploading: [...this.state.FilesUploading, ...Array.from(files)],
        },
        () => {
          e.value = "";
        }
      );
    }
  };

  onCloseUploadFile = () => {
    this.setState({ uploadFile: false, FilesUploading: [] });
  };

  uploadSuccess = (item) => {
    this.setState({
      data: [item, ...this.state.data],
    });
  };

  // Shared file or folder
  openShare = (id) => {
    this.setState({ share_id: id });
  };

  render() {
    const { data, isLoading, openFilter, folder } = this.state;
    const tableHeaders = [
      {
        title: "Tên file",
        dataIndex: "name",
        render: (_, data) => {
          return data.model_type === MEDIA_FILE ? (
            <div
              className="item-name"
              onClick={() => this.popupCenter(data.path, "popup", 800, 500)}
              style={{ cursor: "pointer" }}
            >
              <div className="icon-file">
                <FileIcon
                  extension={data.file_etx}
                  {...defaultStyles[data.file_etx]}
                />
                {/* <img className="image" src={data.path} /> */}
              </div>
              <span>{data.name}</span>
            </div>
          ) : (
            <div
              className="item-name"
              onClick={() => this.onClickRow(data)}
              style={{ cursor: "pointer" }}
            >
              <img
                src={Folder}
                alt="folder icon"
                className="icon-folder mr-2"
              />
              <span>{data.name}</span>
            </div>
          );
        },
      },
      {
        title: "Dung lượng",
        dataIndex: "file_size",
        render: (data) => (data ? sizeOf(data) : "---"),
      },
      {
        title: "Hành động",
        render: (_, data) => {
          return (
            data.model_type === MEDIA_FILE && (
              <div className="d-flex media_icons_action">
                {/* <LinkOutlined rotate={45} /> */}
                <DownloadOutlined
                  onClick={(e) => saveAs(data.path, data.name)}
                />
              </div>
            )
          );
        },
      },
    ];
    return (
      <div className="container medias">
        <div className="mt-5">
          <div className="page-header">
            {this.state.id && (
              <div
                className={`card-header ${
                  this.state.id ? "has_breadcrumb" : ""
                }`}
              >
                <div className="header-breadcrumb">
                  {/* <h4>Quản lí media</h4> */}
                  {isLoading ? (
                    <Skeleton height={25} width={200} />
                  ) : (
                    <Breadcrumb
                      hidden={!this.state.id}
                      breadcrumb={folder && folder.breadcrumb}
                    />
                  )}
                </div>
              </div>
            )}

            {/* <div className="page-right">
              <InputSearch onChange={this.onChangeSearch} />
            </div> */}
          </div>

          <div className="page-content mt-1">
            {/* <div className="card card-custom"> */}

            {/* <div className="card-body"> */}
            <div className="row">
              <div className={`${openFilter ? "col-lg-8" : "col-lg-12"}`}>
                <TableData
                  data={data}
                  columns={tableHeaders}
                  loading={isLoading}
                  rowKey={(record) => record.id}
                  pagination={false}
                />
              </div>
              {openFilter && <div className="col-lg-4">Đây là fileter</div>}
            </div>
          </div>
        </div>
      </div>
      // </div>
      // </div>
    );
  }
}

export default Media;
