import React, { Fragment } from 'react'
import Cart from './Cart'
import Distribution from './Distribution'
import { Route, Switch } from 'react-router-dom';
import Can from '../../../service/Can';

const CartWrapper = () => {
  return <Fragment>
    <Switch>
      <Route exact path="/manager/cart" component={Cart}/>
      <Can I="sale.list" a="sales">
        <Route exact path="/manager/cart/distribution" component={Distribution}/>
      </Can>
    </Switch>
  </Fragment>
}

export {
  CartWrapper as Cart
}