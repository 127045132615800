import requester from "./requester";
import { GET_LIST_SALE_URL } from "../constants/Config";

export default {
  getListSales: (params = {}) =>
    requester.post(
      `${process.env.REACT_APP_ENDPOINT_API_BACKEND}${GET_LIST_SALE_URL}`,
      params
    ),
};
