import React from "react";
import iconCart from "../../../assets/images/icons/icon-cart.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const BlockCart = (props) => {
  const { CartStatistic } = props;

  return (
    <div className="block-cart">
      <div className="row mr-0 ml-0">
        <div className="col-3">
          <div className="item-cart">
            <div className="block-left">
              <img src={iconCart} alt="" />
              <div className="content">
                <p className="title">Tổng số căn:</p>
                <p className="detail">
                  {CartStatistic?.[CartStatistic?.length - 1] || 0}
                </p>
              </div>
            </div>
            <div className="block-right">
              <CircularProgressbar
                value={100}
                text={`${100}%`}
                styles={buildStyles({
                  pathColor: "#00B382",
                  textColor: "#00B382",
                  trailColor: "#F4F4F4",
                })}
              />
            </div>
          </div>
        </div>
        <div className="col-3 ">
          <div className="item-cart">
            <div className="block-left">
              <img src={iconCart} alt="" />
              <div className="content">
                <p className="title">Số căn giữ chỗ:</p>
                <p className="detail">{CartStatistic?.[2] || 0}</p>
              </div>
            </div>
            <div className="block-right">
              <CircularProgressbar
                value={
                  CartStatistic?.total_house
                    ? Math.ceil(
                      (CartStatistic?.deposit / CartStatistic?.total_house) *
                      100
                    )
                    : 0
                }
                text={`${CartStatistic?.total_house
                  ? Math.ceil(
                    (CartStatistic?.deposit / CartStatistic?.total_house) *
                    100
                  )
                  : 0
                  }%`}
                styles={buildStyles({
                  pathColor: "#F53C56",
                  textColor: "#F53C56",
                  trailColor: "#F4F4F4",
                })}
              />
            </div>
          </div>
        </div>
        <div className="col-3 ">
          <div className="item-cart">
            <div className="block-left">
              <img src={iconCart} alt="" />
              <div className="content">
                <p className="title">Số căn đặt cọc:</p>
                <p className="detail">{CartStatistic?.[3] || 0}</p>
              </div>
            </div>
            <div className="block-right">
              <CircularProgressbar
                value={
                  CartStatistic?.total_house
                    ? Math.ceil(
                      (CartStatistic?.reservation /
                        CartStatistic?.total_house) *
                      100
                    )
                    : 0
                }
                text={`${CartStatistic?.total_house
                  ? Math.ceil(
                    (CartStatistic?.reservation /
                      CartStatistic?.total_house) *
                    100
                  )
                  : 0
                  }%`}
                styles={buildStyles({
                  pathColor: "#00B382",
                  textColor: "#00B382",
                  trailColor: "#F4F4F4",
                })}
              />
            </div>
          </div>
        </div>
        <div className="col-3 ">
          <div className="item-cart">
            <div className="block-left">
              <img src={iconCart} alt="" />
              <div className="content">
                <p className="title">Số căn thanh toán:</p>
                <p className="detail">{CartStatistic?.[4] || 0}</p>
              </div>
            </div>
            <div className="block-right">
              <CircularProgressbar
                value={
                  CartStatistic?.total_house
                    ? Math.ceil(
                      (CartStatistic?.payment / CartStatistic?.total_house) *
                      100
                    )
                    : 0
                }
                text={`${CartStatistic?.total_house
                  ? Math.ceil(
                    (CartStatistic?.payment / CartStatistic?.total_house) *
                    100
                  )
                  : 0
                  }%`}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlockCart;
