import React from "react";
import { Table } from "antd";

const TableData = ({ data, columns, pagination, className, ...rest }) => {
  const classNameCustom = +className ? className : "";
  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={pagination}
      className={`table_data ` + classNameCustom}
      {...rest}
    ></Table>
  );
};

export default TableData;
