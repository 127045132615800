import React, { Component } from "react";

class InputSearch extends Component {
  render() {
    return (
      <div className="search-form">
        {/* <label>Search</label> */}
        <div className={`input-wrapper ${this.props.className ? this.props.className : ""}`}>
          <span className="lnr lnr-magnifier"></span>
          <input
            name="search_text"
            className={
              this.props.isSmall
                ? "form-control input-form small"
                : "form-control input-form"
            }
            placeholder="Tìm kiếm..."
            onChange={this.props.onChange}
          />
        </div>
      </div>
    );
  }
}

export default InputSearch;
