import React from 'react';
import { Form, Button, Input } from 'antd';

const Filter = ({ t, loading, handleSubmit }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleSubmit(values);
  }

  const onReset = () => {
    form.resetFields();
    const values = form.getFieldsValue();
    handleSubmit(values);
  }
  
  return (
    <Form form={form} layout='inline' onFinish={onFinish} className="justify-content-center my-3">
      <Form.Item name="search_text">
        <Input placeholder="Tìm kiếm..." prefix={<span className="lnr lnr-magnifier"></span>} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="mr-2" loading={loading}>Tìm kiếm</Button>
        <Button danger htmlType="button" disabled={loading} onClick={onReset}>Làm lại</Button>
      </Form.Item>
    </Form>
  );
}

export default Filter;