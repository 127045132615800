import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import Mail from '../assets/images/icons/mail.svg'
import Password from '../assets/images/icons/key.svg'

import TextError from "../components/TextError";
import { getAPI_BACKEND_URL } from "../utils/base_service";

export default function Login({ loginSuccess, changeAuthUser, showRegister, onCloseLogin }) {
  const { t } = useTranslation();
  const [statusLogin, setStatusLogin] = useState({
    error: false,
    loading: false,
  });
  const loginSchemaValidate = yup.object().shape({
    email: yup
      .string()
      .email(t("form.errorEmail"))
      .required(t("form.errorRequired", { name: "Email" })),
    password: yup
      .string()
      .min(6, t("form.errorPassLeast", { min: 6 }))
      .required(t("form.errorRequired", { name: t("common.password") })),
  });
  const submitLogin = (values) => {
    const url = "/auth/login";
    setStatusLogin({
      error: false,
      loading: true,
    });
    axios
      .post(getAPI_BACKEND_URL(url), values)
      .then((res) => {
        setStatusLogin({
          error: false,
          loading: false,
        });
        loginSuccess({ ...res.data.data, remember_login: values.remember_me });
      })
      .catch((error) => {
        if (error.response && error.response.status === 400) {
          setStatusLogin({
            error: true,
            loading: false,
          });
        }
      });
  };
  return (
    <>
      <div className="wrap-login-form" onClick={onCloseLogin}></div>
      <div className="login-form">
        <div className="form-body">
          <h1 className="title">{t("common.login")}</h1>
          <p className="title description">{t("common.desc_login")}</p>
          <Formik
            initialValues={{
              email: "",
              password: "",
              remember_me: true,
            }}
            validationSchema={loginSchemaValidate}
            onSubmit={submitLogin}
          >
            {({ errors, touched, isValid, dirty }) => (
              <Form>
                <div className="form-group">
                  <div className="form-group_custom">
                    <img className="form-group_icon-mail" src={Mail} />
                    <Field
                      type="text"
                      name="email"
                      placeholder={t("common.email")}
                      className="form-control form-input form-input-email"
                    />
                  </div>
                  {errors.email && touched.email && (
                    <TextError message={errors.email} />
                  )}
                </div>
                <div className="form-group">
                  <div className="form-group_custom">
                    <img className="form-group_icon-password" src={Password} />
                    <Field
                      type="password"
                      name="password"
                      placeholder={t("common.password")}
                      className="form-control form-input form-input-password"
                    />
                  </div>
                  {errors.password && touched.password && (
                    <TextError message={errors.password} />
                  )}
                  <div className="form-link-block">
                    <div className="checkbox-form">
                      <Field type="checkbox" name="remember_me" id="remember" />
                      <label htmlFor="remember">
                        {t("common.remember_me")}
                      </label>
                    </div>
                    <Link to="/" className="link-forgot">
                      {t("common.forgot_password")}
                    </Link>
                  </div>
                </div>
                {statusLogin.error && touched.error && (
                  <TextError message={errors.error} />
                )}
                <p className="auth_redireact_text">
                  {t("form.DonthaveAccount")}{" "}
                  <span onClick={() => changeAuthUser()}> {t("form.signUpNow")} </span>
                </p>
                {statusLogin.error && (
                  <TextError message={t("form.errorLogin")} />
                )}
                <div className="form-button">
                  <button
                    type="submit"
                    disabled={!(isValid && dirty) || statusLogin.loading}
                    className="btn btn-custom  btn-hover-black-neon"
                    style={{ width: `100%` }}
                  >
                    {statusLogin.loading ? (
                      <Spinner animation="border" />
                    ) : (
                        t("header.login")
                      )}
                  </button>
                  <div
                    className="btn btn-custom  btn-hover-black-neon"
                    style={{ width: `100%` }}
                    onClick={() => showRegister()}
                  >
                    {t("header.register")}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
