import React, { Component } from 'react'
import Popup from 'reactjs-popup'
import { getDetailCart, changeStatusCartRequest } from '../../../actions/cart'

export class CartDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            data: {},
            showInputCustomer: false,
            errors: {},
        };
    }

    componentDidMount = async () => {
        let id = this.props.product_id;
        if (id) {
            getDetailCart(id)
                .then((res) => {
                    this.setState({ data: res.data.data });
                })
                .catch((err) => {
                    this.setState({ errors: err.err });
                });
        }
    };

    render() {
        const { open, data, showInputCustomer, errors } = this.state;
        return (
            <div>
                {data &&
                    data.template_product &&
                    data.template_product.area_inside}
            </div>
        )
    }
}

export default CartDetail

