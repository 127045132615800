import React, { useEffect, useState, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
// import Can from '../../services/Can';
import { useQuery, usePagination } from "../../../hooks/";
import { Table, Tooltip } from "antd";
import { objectToQuery } from "../../../utils";
import classNames from "classnames";
import { useContacts } from "../../../hooks/contact";
import Filter from "./Filter";
import Preview from "./Preview";
import ContactRepository from '@repositories/ContactRepository';

const Contact = (props) => {
  let params = useQuery();
  const repository = new ContactRepository();
  const [{ contacts, loading, paginate }, handleSubmit] = useContacts(repository);
  const history = useHistory();
  const [selectedCustomers, setSelectedCustomer] = useState([]);
  const [selectedKeys, setSelectedKey] = useState([]);
  const pagination = usePagination(paginate);
  const [products, setProduct] = useState([]);
  const [preview, setPreview] = useState(false);

  const onClosePreview = () => {
    setPreview(false);
    setProduct([]);
  };

  const onOpenPreview = (products) => {
    setPreview(true);
    setProduct(products);
  };

  const tableCloumns = [
    {
      title: "Tên khách hàng",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Số điện thoại",
      dataIndex: "phone",
      key: "template_product",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Số sản phẩm",
      dataIndex: "products",
      key: "count",
      render: (products) => products.length,
    },
    {
      title: "Ghi chú",
      dataIndex: "note",
      key: "note",
    },
    {
      title: "Hành động",
      dataIndex: null,
      key: "count",
      render: (record) => (
        <div className="btn-tables">
          <Tooltip placement="left" title="Xem chi tiết">
            <button
              className="btn btn-success btn-sm"
              onClick={() => onOpenPreview(record.products)}
            >
              <span className="lnr lnr-magnifier lnr-icon"></span>
            </button>
          </Tooltip>
        </div>
      ),
    },
  ];

  useEffect(() => {
    handleSubmit(params);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedCustomer(selectedRows);
      setSelectedKey(selectedRowKeys);
    },
    getCheckboxProps: (record) => {
      return {
        name: "customer_id",
        key: record.id,
      };
    },
  };

  const handleTableChange = (pagination) => {
    params.page = pagination.current;
    history.push({
      pathname: history.location.pathname,
      search: objectToQuery(params),
    });
    handleSubmit(params);
  };

  const resetSelected = useCallback(() => {
    setSelectedCustomer([]);
    setSelectedKey([]);
  }, []);

  const handleFitler = (values) => {
    params = { ...params, ...values };
    history.push({
      pathname: history.location.pathname,
      search: objectToQuery(params),
    });
    resetSelected();
    handleSubmit(params);
  };
  return (
    <div className="contacts">
      <div className="container">
        <div className="distribution-table">
          <Preview
            onClose={onClosePreview}
            products={products}
            open={preview}
          />
          <div className="filter">
            <Filter loading={loading} handleSubmit={handleFitler} />
          </div>
          <div
            className={classNames("distribution-action", {
              show: selectedKeys.length > 0,
            })}
          >
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={resetSelected}
            >
              Bỏ chọn
            </button>
            <span className="text-success text-small ml-3">
              ({selectedKeys.length} khách hàng được chọn)
            </span>
          </div>
          <Table
            rowSelection={{
              ...rowSelection,
              type: "checkbox",
              selectedRowKeys: selectedKeys,
            }}
            rowKey={(record) => record.id}
            columns={tableCloumns}
            pagination={pagination}
            dataSource={contacts}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
};

export default Contact;
