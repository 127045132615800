import React, { Component } from "react";

class NewButton extends Component {
  render() {
    return (
      <button
        type="button"
        className="btn-new btn-outline"
        {...this.props}
      >
        {/* <span className="lnr lnr-plus-circle mr-1"></span> */}
        <span>Thêm mới</span>
      </button>
    );
  }
}

export default NewButton;
