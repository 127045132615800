import React, { Component } from 'react'
import { getListProductFavorite } from '../../../actions/product'

export class ListView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: [],
            isLoading: false
        }
    }

    componentDidMount() {
        this.loadListFavorite()
    }

    loadListFavorite = () => {
        this.setState({
            isLoading: true,
        })
        getListProductFavorite(this.search)
            .then((res) => {
                this.setState({
                    data: res.data.data,
                    isLoading: false
                })
            })
            .catch((err) => {
                console.error(err)
                this.setState({
                    isLoading: false
                })
            })
    }
    render() {
        let { data } = this.state
        return (
            <>
                <section className="favorite_listview">
                    <div className="container">
                        <table style={{ width: "100%" }}>
                            <tbody>
                                <tr className="favorite_listview_header">
                                    <th>Residence</th>
                                    <th>Type</th>
                                    <th>Room</th>
                                    <th>Size</th>
                                    <th>Price</th>
                                    <th>Detail (pdf)</th>
                                </tr>
                                {data &&
                                    data.map((view, index) => (
                                        <tr
                                            key={index.toString()}
                                            className="favorite_listview_body"
                                        >
                                            <th>
                                                <h4>{view.product_code}</h4>
                                            </th>
                                            <th>
                                                {view.template_product.name}
                                            </th>
                                            <th>
                                                <ul className="favorite_listview_body_list">
                                                    <li>{view.template_product.number_bathroom} Number Bathroom</li>
                                                    <li>{view.template_product.number_bedroom} Number Bedroom</li>
                                                    {
                                                        view.template_product.direction === null ? (
                                                            <li>Hướng chưa xác định</li>
                                                        ) : (
                                                                <li>{view.template_product.direction.name}</li>
                                                            )
                                                    }
                                                </ul>
                                            </th>
                                            <th>{view.template_product.area_inside} SQ.M</th>
                                            <th>${view.template_product.price}</th>
                                            <th>
                                                <ul className="favorite_listview_body_detail">
                                                    <li>
                                                        <a href="/compare/detail_product">View detail</a>
                                                    </li>
                                                </ul>
                                            </th>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                    </div>
                </section>
            </>
        );
    }
}

export default ListView
