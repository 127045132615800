import * as types from "./../constants/actionType";

const setListProductFavorite = (payload) => {
  return {
    type: types.GET_ALL_FAVORITE,
    payload,
  };
};

const addProductFavorite = (payload) => {
  return {
    type: types.ADD_PRODUCT_FAVORITE,
    payload,
  };
};

const removeProductFavorite = (payload) => {
  return {
    type: types.REMOVE_PRODUCT_FAVORITE,
    payload,
  };
};

export { setListProductFavorite, addProductFavorite,  removeProductFavorite};
