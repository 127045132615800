import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

class SaleTag extends Component {
  constructor(props) {
    super(props);
    this.id = this.props.match.params.id;
  }

  render() {
    return (
      <>
        <div className="row d-flex distributor-nav distributor-nav-custom">
          <div className="col-12 col-md-6 col-lg-3">
            <NavLink
              to={`detail`}
              activeClassName="active"
              className="distributor-nav-item distributor-nav-item-custom"
            >
              THÔNG TIN SALE
            </NavLink>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <NavLink
              to={`apartment`}
              activeClassName="active"
              className="distributor-nav-item distributor-nav-item-custom"
            >
              CĂN HỘ ĐANG SALE
            </NavLink>
          </div>
          {/* <div className="col-12 col-md-6 col-lg-3">
            <NavLink
              to={`customers`}
              activeClassName="active"
              className="distributor-nav-item distributor-nav-item-custom"
            >
              KH TIẾP CẬN
            </NavLink>
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <NavLink
              to={`history`}
              activeClassName="active"
              className="distributor-nav-item distributor-nav-item-custom"
            >
              LỊCH SỬ TRA CỨU
            </NavLink>
          </div> */}
        </div>
      </>
    );
  }
}

export default withRouter(SaleTag);
