import Floor from './floor';
import Product from './product';
import Contact from './contact';
import Customer from './customer';
import Cart from './cart';
import Sale from './sale';

export default {
    Floor,
    Product,
    Contact,
    Customer,
    Cart,
    Sale
}