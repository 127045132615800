const localSto_token = "landcaster::token_user";
const localSto_rememberLogin = "landcaster::remember_login";
const API_URL = process.env.REACT_APP_ENDPOINT_API_BACKEND;
const BASE_URL = process.env.REACT_APP_ENDPOINT_ADMIN;
const API_REGISTER = "/user/sign-up";
const AUTH_ROLE = {
  SALE: "Sale",
  CUSTOMER: "customer"
};
const BOOK_ROLES = ["Representation", "Sale"];
const ID_ADMIN_ROLE = [6, 7, 8, 1];
const PRODUCT_BOOKED_STATUS = 3;
const SALE_ASSIGN_STATUS = 2;
const CUSTOMER_ASSIGN_STATUS = 1;
const CUSTOMER_STATUSES = [
  {
    value: 1,
    label: "Khách hàng mới",
  },
  {
    value: 2,
    label: "Tiếp cận",
  },
  {
    value: 3,
    label: "Đang tư vấn",
  },
  {
    value: 4,
    label: "Chốt sale",
  }
];
const CUSTOMER_STATUS_COLORS = {
  1: 'green',
  2: 'blue',
  3: 'cyan',
  4: 'orange',
  5: 'magenta'
}

const PRODUCT_STATUS_COLORS = {
  1: 'green',
  2: 'blue',
  3: 'cyan',
  4: 'orange',
  5: 'magenta'
}

export {
  API_URL,
  API_REGISTER,
  AUTH_ROLE,
  BASE_URL,
  localSto_token,
  ID_ADMIN_ROLE,
  localSto_rememberLogin,
  PRODUCT_BOOKED_STATUS,
  BOOK_ROLES,
  CUSTOMER_STATUSES,
  SALE_ASSIGN_STATUS,
  CUSTOMER_STATUS_COLORS,
  CUSTOMER_ASSIGN_STATUS,
  PRODUCT_STATUS_COLORS
};
