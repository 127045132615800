import React from "react";

// import Alert from '../form/Alert';

const Checkbox = React.forwardRef((props, ref) => {
  let { errors, id, label, wrapperClass, ...input } = props;
  return (
    <div className="custom-control custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        {...input}
        id={id}
        ref={ref}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
});

export default Checkbox;

//${checkError(errors, input.name)}
