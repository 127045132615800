import React, { Component } from 'react'
import { stt } from '../../helpers/table'
import Loading from './LoadingTable'
import ScrollContainer from 'react-indiana-drag-scroll'
export class Table extends Component {
    constructor(props) {
        super(props)
    }

    initTableRow = (data, index) => {
        let { tablePaginate } = this.props;
        let idx = index;

        return (
            <tr key={index} className="customer_listview_body">
                {this.props.tableHeaders.map(function (header, index) {
                    let value = data;
                    if (header.index) {
                        value = data[header.index];
                    }

                    if (header.callback) {
                        value = header.callback(value);
                    }

                    if (tablePaginate && header.index && header.index == 'stt') {
                        value = stt(idx, tablePaginate.current_page, tablePaginate.per_page);
                    }
                    if (header.show == false) {
                        return;
                    }
                    return <th key={index} {...header.option}>{value}</th>
                })}
            </tr>
        );
    }

    render() {
        let { tableHeaders, tableData, isLoading, fixed } = this.props;
        return (
            <ScrollContainer className={`scrollable  ${fixed && 'table-fixed'}`} vertical={false} hideScrollbars={false}>
                <table className="w-100">
                    <tbody className="">
                        <tr className="customer_listview_header">
                            {
                                tableHeaders.map(function (header, index) {
                                    // if (header.show == false) {
                                    //     return;
                                    // }
                                    return <th key={index} className={`table-header ${header.className || ''}`}>{header.label}</th>;
                                })
                            }
                        </tr>
                        {
                            isLoading && <tr>
                                <td className="no-result" colSpan={tableHeaders.length}>
                                    <Loading count="10" />
                                </td>
                            </tr>
                        }

                        {
                            tableData.length == 0 && !isLoading && <tr>
                                <div className="no-result mt-5 text-center" colSpan={tableHeaders.length}>
                                    Không tìm thấy kết quả phù hợp
              </div>
                            </tr>
                        }

                        {
                            tableData.length > 0 && !isLoading && tableData.map(this.initTableRow)
                        }
                    </tbody>
                </table>
            </ScrollContainer>
        );
    }
}

export default Table
