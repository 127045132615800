import React, { Component } from "react";
import Popup from "reactjs-popup";
import {
  shareMediaRequest,
  getMediaPermissionRequest,
} from "../../../actions/media";
import { showMessage } from "../../../helpers/table";
import { getUserSelectRequest } from "../../../actions/user";
import { getDistributorSelectRequest } from "../../../actions/distributor";
import Checkbox from "../../../components/form/Checkbox";
import { getCheckBoxWithNameFromRef } from "../../../helpers/form";
import Loader from "../../../components/common/Loader";

class Shared extends Component {
  constructor(props, context) {
    super(props, context);
    this.data = {};
    this.state = {
      media: null,
      users: [],
      distributors: [],
      open: props.open,
      errors: [],
      isRequest: false,
      isLoading: true,
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.open !== prevState.open) {
      if (nextProps.open) {
        document.body.classList.add("modal-open");
      }
      return { open: nextProps.open };
    } else return null;
  }

  componentDidMount() {
    this.loadUsers();
    this.loadDistributors();
  }

  componentDidUpdate(props, state) {
    if (this.state.open !== state.open && this.state.open) {
      this.loadPermission();
    }
  }

  loadPermission = () => {
    const { share_id } = this.props;
    this.setState({ isLoading: true });
    getMediaPermissionRequest(share_id)
      .then((res) => {
        this.setState({ media: res.data.data, isLoading: false });
      })
      .catch((err) => {
        this.setState({ open: false });
        this.props.openShare(null);
        showMessage("Không tìm thấy media!", false);
      });
  };

  loadUsers = () => {
    getUserSelectRequest()
      .then((res) => {
        this.setState({ users: res.data.data });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  loadDistributors = () => {
    getDistributorSelectRequest()
      .then((res) => {
        this.setState({ distributors: res.data.data });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  openModal = (e) => {
    e.preventDefault();
    document.body.classList.add("modal-open");
    this.setState({ open: true, errors: [] });
  };

  closeModal = (e) => {
    document.body.classList.remove("modal-open");
    this.props.openShare(null);
    this.setState({ open: false, errors: [] });
  };

  onSubmitForm = (e) => {
    e.preventDefault();
    let users = getCheckBoxWithNameFromRef(this.refs, "user");
    let distributors = getCheckBoxWithNameFromRef(this.refs, "distributor");
    const formData = { users, distributors };
    const { share_id } = this.props;

    this.setState({ isRequest: true });
    shareMediaRequest(share_id, formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.setState({ isRequest: false, open: false });
        this.props.openShare(null);
      })
      .catch((err) => {
        console.error(err);
        this.setState({ isRequest: false });
      });
  };

  // Search text function
  onSearchUser = (e) => {
    let value = e.target.value;
    let filter, wrapper, item, name, i, txtValue;

    filter = value.toLowerCase();
    wrapper = document.getElementById("list_user");
    item = wrapper.getElementsByClassName("item-user");
    for (i = 0; i < item.length; i++) {
      name = item[i].getElementsByClassName("user_name")[0];
      txtValue = name.textContent || name.innerText;
      if (txtValue.toLowerCase().indexOf(filter) > -1) {
        item[i].style.display = "";
      } else {
        item[i].style.display = "none";
      }
    }
  };

  onSearchDistributor = (e) => {
    let value = e.target.value;
    let filter, wrapper, item, name, i, txtValue;

    filter = value.toLowerCase();
    wrapper = document.getElementById("list_distributors");
    item = wrapper.getElementsByClassName("item-user");
    for (i = 0; i < item.length; i++) {
      name = item[i].getElementsByClassName("user_name")[0];
      txtValue = name.textContent || name.innerText;
      if (txtValue.toLowerCase().indexOf(filter) > -1) {
        item[i].style.display = "";
      } else {
        item[i].style.display = "none";
      }
    }
  };

  render() {
    const {
      open,
      isRequest,
      users,
      distributors,
      isLoading,
      media,
    } = this.state;
    const { className } = this.props;

    return (
      <div className={`custom-popup ${className}`}>
        <Popup open={open} modal closeOnDocumentClick={false}>
          <div className="modal-custom modal-shared">
            <div className="popup-header pt-3 pb-2 px-3">
              <h5 className="mb-0">
                <i
                  className="fa fa-share-alt icon-shared"
                  aria-hidden="true"
                ></i>
                Chia sẽ media
              </h5>
            </div>
            <span
              className="lnr lnr-cross-circle close-btn"
              onClick={(e) => this.closeModal(e)}
            ></span>
            <div className="form-modal text-left p-3">
              {isLoading ? (
                <Loader className="loader-form" />
              ) : (
                <form className="row" onSubmit={this.onSubmitForm}>
                  <div className="col-lg-12 list-shared">
                    <nav>
                      <div
                        className="nav nav-tabs custom-tab"
                        id="nav-tab"
                        role="tablist"
                      >
                        <a
                          className="nav-item nav-link active"
                          id="users-tab"
                          data-toggle="tab"
                          href="#users"
                          role="tab"
                          aria-controls="users"
                          aria-selected="true"
                        >
                          Người dùng
                        </a>
                        <a
                          className="nav-item nav-link"
                          id="distributor-tab"
                          data-toggle="tab"
                          href="#distributor"
                          role="tab"
                          aria-controls="distributor"
                          aria-selected="false"
                        >
                          Nhà phân phối
                        </a>
                      </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="users"
                        role="tabpanel"
                        aria-labelledby="users-tab"
                      >
                        <input
                          onKeyUp={this.onSearchUser}
                          type="text"
                          className="form-control input_search input-form search_text"
                          placeholder="Tìm người dùng..."
                        />

                        <div className="list-choose" id="list_user">
                          {users.map((item) => {
                            return (
                              <div className="item-user" key={item.id}>
                                <div className="item-user_avatar">
                                  <img src={item.avatar} alt={item.name} />
                                </div>
                                <div className="item-user_info">
                                  <p className="user_name">{item.name}</p>
                                  <span>{item.email}</span>
                                </div>
                                <div className="item-user_checked">
                                  <Checkbox
                                    errors={[]}
                                    label=""
                                    wrapperClass="pl-4 custom-checkbox height-100 d-flex align-items-flex-start"
                                    id={`user_${item.id}`}
                                    ref={`user_${item.id}`}
                                    value={item.id}
                                    className="custom-control-input"
                                    multiple={true}
                                    defaultChecked={
                                      media && media.user_ids.includes(item.id)
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="distributor"
                        role="tabpanel"
                        aria-labelledby="distributor-tab"
                      >
                        <input
                          onKeyUp={this.onSearchDistributor}
                          type="text"
                          className="form-control input_search input-form search_text"
                          placeholder="Tìm nhà phân phối..."
                        />

                        <div className="list-choose" id="list_distributors">
                          {distributors.map((item) => {
                            return (
                              <div className="item-user" key={item.id}>
                                <div className="item-user_avatar">
                                  <img src={item.avatar} alt="img" />
                                </div>
                                <div className="item-user_info">
                                  <p className="user_name">{item.name}</p>
                                  <span>{item.email}</span>
                                </div>
                                <div className="item-user_checked">
                                  <Checkbox
                                    errors={[]}
                                    label=""
                                    wrapperClass="pl-4 custom-checkbox height-100 d-flex align-items-flex-start"
                                    id={`distributor_${item.id}`}
                                    ref={`distributor_${item.id}`}
                                    value={item.id}
                                    className="custom-control-input"
                                    multiple={true}
                                    defaultChecked={
                                      media &&
                                      media.distributor_ids.includes(item.id)
                                    }
                                  />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="btn-footer float-right mt-1">
                      <button
                        className="btn btn-custom btn-success"
                        disabled={isRequest}
                      >
                        Hoàn thành{" "}
                        {isRequest && (
                          <i className="ml-1 fa fa-circle-o-notch fa-spin"></i>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              )}
            </div>
          </div>
        </Popup>
      </div>
    );
  }
}

export default Shared;
