import * as types from "./../constants/actionType";

const initialState = {
  data: [],
  paginate: {},
  isLoading: true,
};

const Sale = (state = initialState, action) => {
  switch (action.type) {
    case types.SALE_REQUEST:
      return {
        ...state,
        isLoading: action.status,
      };
    case types.GET_SALE_LIST:
      return {
        ...state,
        data: [...action.data],
        paginate: { ...action.paginate },
        isLoading: false,
      };
    default:
      return { ...state };
  }
};

export default Sale;
