import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';

class LoadingTable extends Component {
  render() {
    let { count } = this.props;
    return (
      <div className="loading-table">
        <Skeleton height={60} count={count} />
      </div>
    );
  }
}

export default LoadingTable;
