import React, { Component } from "react";
import InputText from "../../../components/common/InputText";
import { Link, withRouter } from "react-router-dom";
import InputPhoneCustom from "../../../components/common/InputPhone";
import Select from "../../../components/common/SelectCustom";
import { getTakenData, getAPI_BACKEND_URL } from "../../../utils/base_service";
import { LIST_DISTRIBUTOR } from "../../../constants/Config";
import { getFormDataFromRef } from "../../../helpers/form";
import { createSaleRequest } from "../../../actions/sale";
import { showMessage } from "../../../helpers/table";
class SaleNew extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDistributor: [],
      errors: {},
    };
  }

  componentDidMount = async () => {
    let listDistributor = await getTakenData(
      getAPI_BACKEND_URL(LIST_DISTRIBUTOR)
    );
    this.setState({
      listDistributor: listDistributor.data.data,
    });
  };

  onCreateSale = (e) => {
    e.preventDefault();
    var formData = getFormDataFromRef(this.refs);
    let val = formData.phone;
    formData.phone = val
      .replace(/\D/g, "")
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        if (t) {
          return `${f}${s}${t}`;
        } else if (s) {
          return `${f}${s}`;
        } else if (f) {
          return `${f}`;
        }
      });
    formData = { ...formData };
    createSaleRequest(formData)
      .then((res) => {
        showMessage(res.data.message, true);
        this.props.history.goBack();
      })
      .catch((err) => {
        this.setState({ errors: err.err });
      });
  };

  render() {
    let { errors, listDistributor } = this.state;


    return (
      <>
        <div className="container-fluid container-shadow">
          <div className="row top-table">
            <div className="col-md-6 top-table-title">
              <p>Thêm mới Sale</p>
            </div>
            <div className="col-md-6 d-flex">
              <div className="button-new ml-auto">
                <span onClick={this.onCreateSale}>Lưu thông tin</span>
              </div>
              <div className="button-new ">
                <Link to="/manager/sale_list">
                  <span>Hủy</span>
                </Link>
              </div>
            </div>
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder="Họ và tên"
                name="full_name"
                ref="full_name"
                label="Họ và tên:"
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder="Địa chỉ"
                name="address"
                ref="address"
                label="Địa chỉ:"
                errors={errors}
                type="text"
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputPhoneCustom
                name="phone"
                ref="phone"
                label="Số điện thoại:"
                type="text"
                className="form-control input-form"
                placeholder="Số điện thoại"
                errors={errors}
              />
            </div>
            <div className="col-md-6 flex-wrap">
              <InputText
                className="form-control input-form"
                placeholder="Email"
                name="email"
                ref="email"
                label="Email:"
                errors={errors}
                type="text"
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SaleNew);
