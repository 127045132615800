import React, { Component } from "react";
import {
  getTakenData,
  getAPI_BACKEND_URL,
  getDataByID,
} from "../../../utils/base_service";
import CartDetail from "./CartDetail";
import {
  GET_CATEGORY_PRODUCT_URL,
  GET_BLOCK_FOLLOW_CATEGORY_URL,
  GET_BUILDING_BLOCK,
  GET_FLOOR_BUILDING,
  GET_LIST_CUSTOMER_URL,
  GET_PHASE_URL,
  GET_STATUS_URL,
} from "../../../constants/Config";
import { getListCart, getStaticsCart } from "../../../actions/cart";
import InputSearch from "../../../components/common/InputSearch";
import { Col } from "react-bootstrap";
import icon from "../../../assets/images/icons/Group 3899@2x.png";
import "../../../assets/styles/manager/filterForm.scss";
import "../../../assets/styles/manager/cart.scss";
import SelectCustom from "../../../components/common/SelectCustom";
import { Filter } from "react-feather";
import FilterCart from "./FilterCart";
import { Popover, Spin } from "antd";
import DetailBlock from "./DetailBlock";
import { Link } from "react-router-dom";
import Can from "../../../service/Can";
import DetailCustomer from "../Customer/DetailCustomer";

class Cart extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.state = {
      dataDetailsTableModal: {},
      ModalStatus: false,
      ModalDetail: false,
      FilterStatus: false,
      openBlock: false,
      dataCategory: [],
      dataBlock: [],
      dataBuilding: [],
      dataFloor: [],
      data: [],
      isLoading: false,
      product_id: null,
      dataBedRoom: [],
      dataBathRoom: [],
      dataArea: null,
      dataPrice: null,
      dataStatic: [],
      dataPhase: [],
      dataStatus: [],
      idStatus: null,
    };
  }

  componentDidMount() {
    this.loadDataFilter();
    this.loadListCart();
    this.loadStaticCart();
    this.loadPhaseCart();
    this.loadStatusCart();
  }

  loadStatusCart = () => {
    getTakenData(getAPI_BACKEND_URL(GET_STATUS_URL)).then((res) => {
      this.setState({
        dataStatus: res.data,
      });
    });
  };

  loadPhaseCart = () => {
    getTakenData(getAPI_BACKEND_URL(GET_PHASE_URL)).then((res) => {
      this.setState({
        dataPhase: res.data.data,
      });
    });
  };

  loadDataFilter = () => {
    getTakenData(getAPI_BACKEND_URL(GET_CATEGORY_PRODUCT_URL)).then((res) => {
      this.setState({
        dataCategory: res.data.data,
      });
    });
  };

  loadListCart = (params) => {
    this.setState({
      isLoading: true,
    });
    getListCart(params)
      .then((res) => {
        this.setState({
          data: res.data.data,
          isLoading: false,
        });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          isLoading: false,
        });
      });
  };

  loadStaticCart = () => {
    getStaticsCart().then((res) => {
      this.setState({
        dataStatic: res.data,
      });
    });
  };

  onChangeSearch = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;

    this.search = {
      ...this.search,
      [name]: value,
    };
    this.loadListCart(this.search);
  };

  onChangePhase = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    this.search = {
      ...this.search,
      [name]: value,
    };

    this.loadListCart(this.search);
  };

  onChangeCategory = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;

    getDataByID(getAPI_BACKEND_URL(GET_BLOCK_FOLLOW_CATEGORY_URL), value).then(
      (res) =>
        this.setState({
          dataBlock: res.data.data,
        })
    );
    this.setState({
      dataBlock: [],
      dataBuilding: [],
      dataFloor: [],
    });
    this.search = {
      ...this.search,
      [name]: value,
      block_id: null,
      building_id: null,
      floor_id: null,
    };

    if (!e.target.value) {
      this.setState({
        dataBlock: [],
        dataBuilding: [],
        dataFloor: [],
      });
      this.search = {
        ...this.search,
        block_id: null,
        building_id: null,
        floor_id: null,
      };
    }
    this.loadListCart(this.search);
  };

  onChangeBlock = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;

    getDataByID(getAPI_BACKEND_URL(GET_BUILDING_BLOCK), value).then((res) =>
      this.setState({
        dataBuilding: res.data.data,
      })
    );
    this.setState({
      dataBuilding: [],
      dataFloor: [],
    });
    this.search = {
      ...this.search,
      [name]: value,
      building_id: null,
      floor_id: null,
    };

    if (!e.target.value) {
      this.setState({
        dataBuilding: [],
        dataFloor: [],
      });
      this.search = {
        ...this.search,
        building_id: null,
        floor_id: null,
      };
    }

    this.loadListCart(this.search);
  };

  onChangeBuilding = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    getDataByID(getAPI_BACKEND_URL(GET_FLOOR_BUILDING), value).then((res) =>
      this.setState({
        dataFloor: res.data.data,
      })
    );

    this.setState({
      dataFloor: [],
    });

    this.search = {
      ...this.search,
      [name]: value,
      floor_id: null,
    };

    if (!e.target.value) {
      this.setState({
        dataFloor: [],
      });
      this.search = {
        ...this.search,
        floor_id: null,
      };
    }

    this.loadListCart(this.search);
  };

  onChangeFloor = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : null;
    this.search = {
      ...this.search,
      [name]: value,
    };

    this.loadListCart(this.search);
  };

  onFilterListCart = (dataBedRoom, dataBathRoom, dataArea, dataPrice) => {
    this.setState({
      dataBedRoom,
      dataBathRoom,
      dataArea: dataArea,
      dataPrice: dataPrice,
    });
    const filterParams = {
      area_inside: dataArea,
      status: dataBedRoom.map((item) => parseInt(item)),
      number_bathroom: dataBathRoom.map((item) => parseInt(item)),
      price: dataPrice,
    };
    this.loadListCart(filterParams);
  };
  showFilter = () => {
    let { FilterStatus } = this.state;
    this.setState({ FilterStatus: !FilterStatus });
  };
  hideFilter = () => {
    this.setState({ FilterStatus: false });
  };

  // detailBlock = (e, status, id) => {
  //     e.preventDefault();
  //     this.setState({ openBlock: status, product_id: id });
  // };

  detailProduct = (id) => {
    this.props.history.push(`/product/${id}/detail`);
  };

  onFilterStatus = (id) => {
    this.search = {
      ...this.search,
      status: id,
    };
    this.setState({
      idStatus: id,
    });
    this.loadListCart(this.search);
  };

  closeModalDetail = () => {
    this.setState({ ModalDetail: false });
  };

  showModalDetail = (data) => {
    this.setState({ ModalDetail: true, dataDetailsTableModal: data });
  };

  contentPopover = (data) => (
    <div className="text-center pt-2" style={{ minWidth: 200 }}>
      {data.customer && (
        <p
          onClick={() => this.showModalDetail(data.customer)}
          className="mb-1 cursor-pointer"
        >
          <span className="font-weight-bold">Khách hàng :&nbsp;&nbsp;</span>
          {data.customer.name}
        </p>
      )}
      {data.salers.length !== 0 && (
        <p className="mb-1">
          <span className="font-weight-bold">Người bán :&nbsp;&nbsp;</span>
          {data.salers.map((item) => (
            <a
              href={`/manager/sale_list/${item.id}/detail`}
              rel="noopener noreferrer"
              target="_blank"
            >
              {item.name} &nbsp;
            </a>
          ))}
        </p>
      )}
      <p
        className="mb-0 mt-2 cursor-pointer pt-2 border-top"
        onClick={() => this.detailProduct(data.id)}
      >
        Detail
      </p>
    </div>
  );

  render() {
    let {
      openBlock,
      dataCategory,
      dataBlock,
      dataBuilding,
      dataFloor,
      data,
      isLoading,
      product_id,
      FilterStatus,
      dataStatic,
      dataPhase,
      dataStatus,
      idStatus,
    } = this.state;

    return (
      <>
        <DetailCustomer
          data={this.state.dataDetailsTableModal}
          show={this.state.ModalDetail}
          onHide={this.closeModalDetail}
          onlyRead
        />
        <div className="container manage-cart">
          <Can I="sale.list" a="sales">
            <div className="btn-header text-right">
              <Link
                className="btn btn-primary btn-sm"
                to="/manager/cart/distribution"
              >
                Chia giỏ hàng
              </Link>
            </div>
          </Can>
          <div className="row cart_header">
            {dataStatus &&
              dataStatus.map((item, index) => {
                if (index < 4) {
                  return (
                    <>
                      <Col
                        xl={3}
                        lg={4}
                        md={6}
                        sm={12}
                        className="cart_header_col mb-3"
                      >
                        <div
                          onClick={() => this.onFilterStatus(item.id)}
                          style={{ backgroundColor: item?.color }}
                          key={index}
                          className={`container d-flex cart_header_item cursor-pointer cart_header_item_active ${idStatus === item.id
                              ? "cart_header_item_active--active"
                              : ""
                            }`}
                        >
                          <div className="cart_header_item_title text-white">
                            <p>{item.name}</p>
                            <span>{item.amount || 0}</span>
                          </div>
                          <div
                            className={`cart_header_item_room text-${item && item.status
                              }`}
                          >
                            Căn
                          </div>
                          <img src={icon} alt="" />
                        </div>
                      </Col>
                    </>
                  );
                }
              })}
          </div>
          <div className="row cart_option pt-4 m-auto justify-content-start align-items-start">
            {/* <div className="col-md-3">
                            <SelectCustom
                                className="form-control"
                                name="category_block_id"
                                ref="category_block_id"
                                label="Loại BĐS"
                                include_blank="Loại BĐS"
                                key_value="id"
                                key_label="name"
                                data={dataCategory}
                                errors={[]}
                                onChange={(e) => this.onChangeCategory(e)}
                            />
                        </div> */}
            {/* <div className="col-md-3">
                            <SelectCustom
                                className="form-control"
                                name="block_id"
                                ref="block_id"
                                label="Block"
                                include_blank="Block"
                                key_value="id"
                                key_label="name"
                                data={dataBlock}
                                errors={[]}
                                onChange={(e) => this.onChangeBlock(e)}
                            />
                        </div> */}
            <div className="col-md-3">
              <SelectCustom
                className="form-control"
                name="building_id"
                ref="building_id"
                label="Toà nhà"
                include_blank="Toà nhà"
                key_value="id"
                key_label="name"
                data={dataBuilding}
                errors={[]}
                onChange={(e) => this.onChangeBuilding(e)}
              />
            </div>
            <div className="col-md-3">
              <SelectCustom
                className="form-control"
                name="floor_id"
                ref="floor_id"
                label="Tầng"
                include_blank="Tầng"
                key_value="id"
                key_label="name"
                data={dataFloor}
                errors={[]}
                onChange={(e) => this.onChangeFloor(e)}
              />
            </div>
            <div className="col-md-3">
              <InputSearch
                className="w-100"
                onChange={this.onChangeSearch}
                isSmall
              />
            </div>
            <div className="col-md-3">
              <div className="cart-filters">
                <div
                  className={`btn btn-filter d-flex align-items-center ${FilterStatus == true ? "active" : ""
                    }`}
                  onClick={this.showFilter}
                >
                  <Filter />
                  Bộ lọc
                </div>
                <FilterCart
                  data={data}
                  min={1}
                  max={100}
                  className={`${FilterStatus == true ? "show" : ""}`}
                  // show={FilterStatus}
                  // onHide={this.hideFilter}
                  onSubmitFilter={this.onFilterListCart}
                />
              </div>
            </div>

            {openBlock ? (
              <DetailBlock
                open={openBlock}
                product_id={product_id}
                detailBlock={this.detailBlock}
                onCloseForm={this.onCloseForm}
              />
            ) : (
              ""
            )}
          </div>
          <div className="row cart_option pt-4 m-auto align-items-start">
            {/* <div className="col-md-3">
                            <SelectCustom
                                className="form-control"
                                name="stage_sale_id"
                                ref="stage_sale_id"
                                label="Giai đoạn"
                                include_blank="Giai đoạn"
                                key_value="id"
                                key_label="name"
                                data={dataPhase}
                                errors={[]}
                                onChange={(e) => this.onChangePhase(e)}
                            />
                        </div> */}
          </div>
          <div className="row mt-3">
            {isLoading ? (
              <div className="col-md-12 block-loading">
                <div className="d-flex justify-content-center">
                  <Spin />
                </div>
              </div>
            ) : data.length > 0 ? (
              data.map((item, index) => {
                return (
                  <div className="col-md-2" key={index}>
                    <Popover content={this.contentPopover(item)}>
                      <p
                        style={{ backgroundColor: item.color }}
                        className={`title-cart title-cart-${item && item.status
                          } title-cart-block`}
                      // onClick={(e) => this.detailBlock(e, true, item.id)}
                      // onClick={() => this.detailProduct(item.id)}
                      >
                        {item?.product_code}
                      </p>
                    </Popover>
                  </div>
                );
              })
            ) : (
              <div className="col-12">
                <p className="text-not-found pl-3">Không tìm được kết quả.</p>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default Cart;
