import React from 'react'
import HeaderOrder from './HeaderOrder';
import ListOrder from './ListOrder'
const MainOrder = () => {
    return (
        <div>
            <HeaderOrder/>
            <ListOrder/>
        </div>
    )
}

export default MainOrder
