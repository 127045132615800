export const BASE_IMG = "http://api.bds.fservingtech.com/";
// export const BASE_URL = 'http://localhost:8000/api/v1/';

// AUTHENTICATION URL
export const LOGIN_URL = "/auth/login";
export const GET_USER_URL = "/me";
export const LOGOUT_URL = "auth/logout";

export const GET_DISTRIBUTOR_URL = "distributor/list";
export const GET_DISTRIBUTOR_SELECT_URL = "/distributor/select";
export const NEW_DISTRIBUTOR_URL = "distributor";
export const DELETE_DISTRIBUTOR_URL = "distributor";
export const DETAIL_DISTRIBUTOR_URL = "distributor";
export const UPDATE_DISTRIBUTOR_URL = "distributor";
export const LIST_DISTRIBUTOR = "/distributor/select";

export const UPLOAD_URL = "upload/image";

//ROLES

export const GET_ROLES_URL = "roles";
export const GET_PERMISSIONS_URL = "group_permissions";
export const NEW_PERMISSIONS_URL = "role";
export const DETAIL_PERMISSIONS_URL = "role";
export const UPDATE_PERMISSIONS_URL = "role";
export const DELETE_PERMISSIONS_URL = "role";
export const LIST_ROLES = "roles/list";

// USER
export const UPDATE_USER_URL = "user";
export const CHANGE_PASSWORD = "user/change-pass";
export const GET_LIST_USER_URL = "/user/list";
export const DELETE_USER_URL = "user";
export const NEW_USER_URL = "user";
export const DETAIL_USER_URL = "user";
export const USER_SELECT_URL = "/users/list";
export const LIST_POSITION = "user/positions";

// MEDIA URL
export const MEDIA_URL = (id = null) =>
  id ? `/media-folders/${id}` : "/media-folders";
export const UPDATE_MEDIA_FOLDER_URL = (id) => `media-folders/update/${id}`;
export const DELETE_MEDIA_FOLDER_URL = (id) => `media-folders/delete/${id}`;
export const MEDIA_PERMISSION_URL = (id) =>
  `media-folders/share-permission/${id}`;
export const SHARE_MEDIA_URL = (id) => `media-folders/share/${id}`;
export const NEW_MEDIA_FOLDER_URL = "media-folders/new";

//PRODUCT PUBLIC
export const PRODUCT_PUBLIC_URL = `/product-public`;

//BLOCK PRODUCT

export const NEW_BLOCK_CATE_URL = "categories-block";
export const UPDATE_BLOCK_CATE_URL = "categories-block";
export const GET_BLOCK_CATE_URL = "categories-block/list";
export const DELETE_BLOCK_CATE_URL = "categories-block";
export const GET_BLOCK_CATE_LIST = "categories-block";

//BLOCK
export const NEW_BLOCK_URL = "block";
export const UPDATE_BLOCK_URL = "block";
export const DELETE_BLOCK_URL = "block";
export const GET_BLOCK_URL = "block/list";
export const GET_BLOCK_LIST = "block";

//BUILDING

export const NEW_BUILDING_URL = "building";
export const UPDATE_BUILDING_URL = "building";
export const DELETE_BUILDING_URL = "building";
export const GET_BUILDING_URL = "building/list";
export const GET_BUILDING_LIST = "building";
export const GET_BUILDING_BLOCK = "/building/block";
//FLOOR

export const NEW_FLOOR_URL = "floor";
export const UPDATE_FLOOR_URL = "floor";
export const DELETE_FLOOR_URL = "floor";
export const GET_FLOOR_URL = "template-floor";
export const GET_FLOOR_LIST = "template-floor/list";
export const GET_FLOOR_BUILDING = "/floor/building";

// CUSTOMER
export const GET_LIST_CUSTOMER_URL = "/customer/list";
export const DELETE_CUSTOMER_URL = "customer";
export const DETAIL_CUSTOMER_URL = "customer";
export const UPDATE_CUSTOMER_URL = "customer";
export const CREATE_CUSTOMER_URL = "/customer";
export const DEVIDE_CUSTOMER_SALE_URL = "/customer/saler";

export const GET_FAVORITE_CUSTOMER_URL = "customer/product-favorite";

export const CREATE_MEDIA_FILE_URL = "/media-files/new";

// SALES POLICY
export const GET_LIST_SALES_POLICY_URL = "sales_pocily/list";
export const DELETE_SALES_POLICY_URL = "sales_pocily";
export const DETAIL_SALES_POLICY_URL = "sales_pocily";
export const UPDATE_SALES_POLICY_URL = "sales_pocily";
export const CREATE_SALES_POLICY_URL = "sales_pocily";

// UPLOAD FILE
export const UPLOAD_FILE = "upload/file";

//PRODUCT_TEMPLATE
export const DIREICTION_PRODUCT = "template-product/direction";
export const CREATE_TEMP_PRODUCT = "template-product";
export const GET_LIST_TEMP_PRODUCT = "template-product";
export const LIST_TEMP_PRODUCT = "template-product/list";
export const DELETE_TEMP_PRODUCT = "template-product";
export const GET_TEMP_PRODUCT = "template-product";
export const UPDATE_TEMP_PRODUCT = "template-product";

//FLOOR_TEMPLATE
export const LIST_TEMP_PRODUCT_FLOOR = "template-floor/list";
export const CREATE_TEMP_PRODUCT_FLOOR = "template-floor";
export const DELETE_TEMP_PRODUCT_FLOOR = "template-floor";
export const GET_TEMP_PRODUCT_FLOOR = "template-floor";
export const UPDATE_TEMP_PRODUCT_FLOOR = "template-floor";
export const UPDATE_TEMP_PRODUCT_FLOOR_LIST = "template-floor";

//FLOOR PUBLIC
export const PUBLIC_FLOOR_URL = "floor-public";

//PRODUCT
export const CREATE_MULTI_PRODUCT = "product/multi";
export const LIST_PRODUCT_REQUEST = "product/list";
export const DELETE_PRODUCT_REQUEST = "product";
export const CREATE_PRODUCT_REQUEST = "product";
export const UPDATE_PRODUCT_REQUEST = "product";
export const GET_PRODUCT_INFO = "product";
export const FILTER_PUBLIC_PRODUCT_URL = "product-public/filter";

// SALE
export const GET_LIST_SALE_URL = "/sale/list";
export const DELETE_SALE_URL = "/user";
export const DETAIL_SALE_URL = "/sale";
export const CREATE_SALE_URL = "/sale";
export const GET_PROJECT_SALE_URL = "/sale/product";
export const GET_CUSTOMER_SALE_URL = "/sale/customer";

// CATEGORY PRODUCT
export const GET_CATEGORY_PRODUCT_URL = "/categories-block";
// CATEGORY PRODUCT
export const GET_BLOCK_FOLLOW_CATEGORY_URL = "/block/category";

// CART
export const GET_LIST_CART_URL = "/cart/list-product";
export const DETAIL_CART_URL = "/product";
export const DEVIDE_CART_URL = "/product/saler";
export const CHANGE_STATUS_CART_URL = "product/status";
export const GET_STATICS_CART_URL = "/cart/statistic";

// SEARCH CUSTOMER
export const LIST_SEARCH_CUSTOMER_URL = "/customer/list-select";

//PRODUCT PUBLIC
export const GET_LIST_PRODUCT_PUBLIC = "/product-public";

//PRODUCT FAVORITE
export const GET_LIST_PRODUCT_FAVORITE = "/favorite";
export const LIKE_PRODUCT = "/favorite/like-product";

export const GET_LIST_PRODUCT_IMAGE = "/file_product_public/list"

//ORDER
export const ORDER_PRODUCT_URL = "/order";
// DASHBOARD
export const GET_DETAIL_DASHBOARD = "/dashboard";

// SALE 
export const GET_PHASE_URL = "/stage-sale";
export const GET_STATUS_URL = "/cart/statistic";

// Contacts
export const CREATE_CONTACT_URL = 'contacts/create'
export const GET_CONTACT_URL = "contacts";

//product stauts
export const PRODUCT_STATUS = '/product/status'

export const GET_LIST_NEWS = "/news";
export const GET_DETAIL_NEWS = (id) => `/news/${id}`;

export const GET_LIST_IMAGE_PRODUCT = '/file_product_public/list/template-product'