import BaseRepository from './BaseRepository';
import { API_URL } from '@constants';
import { GET_CONTACT_URL } from '@constants/Config';

class ContactRepository extends BaseRepository{
  constructor(){
    super(API_URL);
  }

  /* Get all contacts */
  getContacts = (params) => {
    return this.get(GET_CONTACT_URL, params);
  }
}

export default ContactRepository;