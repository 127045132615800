import React from "react";
import { Link } from "react-router-dom";
import { Row } from "react-bootstrap";

const Breadcrumb = (props) => {
  let { breadcrumb, hidden } = props;
  if (!breadcrumb) breadcrumb = [];

  if (hidden) return "";

  return (
    <ul className="small-breadcrumb">
      <li className="breadcrumb-item">
        <Link to="/manager/media">
          <span class="lnr lnr-database breadcrumb-icon mr-1"></span>
          Tài liệu
        </Link>
      </li>
      {breadcrumb &&
        breadcrumb.map((item, index) => {
          return index < breadcrumb.length - 1 ? (
            <li className="breadcrumb-item" key={item.id}>
              <Link to={`/manager/media/${item.id}`}>
                {item.name}
              </Link>
            </li>
          ) : (
            <li
              className="breadcrumb-item active"
              aria-current="page"
              key={item.id}
            >
              {item.name}
            </li>
          );
        })}
    </ul>
  );
};

export default Breadcrumb;
