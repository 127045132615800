import React from "react";
import Popup from "reactjs-popup";
import { Table, Tag, Button } from "antd";
import { useHistory } from "react-router-dom";
import { moneyFormat } from "../../../helpers/table";
import { PRODUCT_STATUS_COLORS } from "../../../constants";

const Preview = ({ products, open, onClose }) => {
  const history = useHistory();
  const tableCloumns = [
    {
      title: "Mã căn",
      dataIndex: "product_code",
      key: "product_code",
    },
    {
      title: "Loại căn",
      dataIndex: "template_product",
      key: "template_product",
      render: (template) => template?.name,
    },
    {
      title: "Giá bán",
      dataIndex: null,
      key: "price",
      render: ({ current_price, price }) =>
        current_price ? moneyFormat(current_price) : current_price(price),
    },
    {
      title: "Đơn vị phân phối",
      dataIndex: "distributor",
      key: "distributor",
      render: (distributor) => distributor?.name,
    },
    {
      title: "Trạng thái",
      dataIndex: null,
      key: "email",
      render: ({ status, status_lable }) => (
        <Tag color={PRODUCT_STATUS_COLORS[status]}>{status_lable}</Tag>
      ),
    },
  ];

  const closePreview = () => {
    onClose();
  };

  return (
    <div className="product-preview">
      <Popup
        open={open}
        modal
        closeOnDocumentClick={false}
        className="preview custom-popup"
        onClose={closePreview}
      >
        <div className="header">
          <h3 className="header-title">Danh sách sản phẩm quan tâm</h3>
          <span className="close-btn" onClick={closePreview}></span>
        </div>
        <div className="body">
          <Table
            rowClassName="cursor-pointer"
            rowKey={(record) => record.id}
            columns={tableCloumns}
            dataSource={products}
            scroll={{ y: 300 }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  history.push(`/product/${record.id}/detail`);
                },
              };
            }}
          />
        </div>
      </Popup>
    </div>
  );
};

export default Preview;
