import React from "react";
import { Switch, Route } from "react-router-dom";
import { Order } from "../container/manager/Order";

const OrderRoutes = (props) => {
  const routes = [
    {
      path: "/manager/order/:id?",
      render: (props) => <Order {...props} />,
    },
  ];

  return (
    <div>
      <Switch>
        {" "}
        {routes.map((item, index) => {
          return <Route {...item} key={index} />;
        })}{" "}
      </Switch>{" "}
    </div>
  );
};

export default OrderRoutes;
