import { stringify } from 'qs';

export const queryToObject = (entries) => {
    const result = {}
    for(const [key, value] of entries) {
      result[key] = value;
    }
    return result;
}
  
export const objectToQuery = (params = {}) => {
  return  stringify(params,{ arrayFormat: 'repeat' });
}

export const formatMoney = (num = 0, prefix = 'đ') => {
  num = Number((num).toFixed(3)).toLocaleString();
  return `${num} ${prefix}`
}

/**
 * In an array of objects errors, return standard form errors for antd form.
 * @param   {errors}     object     The object errors from server.
 * @param   {key}     string     Key of object error in response error.
 * @return  {array}    Return a key array.
 */
 export function transformErrors(errors, key = 'errors') {
  let errs = errors && errors.hasOwnProperty(key) ? errors[key] : {};
  return Object.keys(errs).map((key) => ({
    name: key,
    errors: errs[key],
  }));
}