import React, { useState } from 'react';
import { checkError, getError } from '../../helpers/error';
import Alert from './Alert';
import InputMask from "react-input-mask";

const InputPhoneCustom = React.forwardRef((props, ref) => {
  let { errors, defaultValue, ...input } = props;

  const [value, setValue] = useState({
    value: defaultValue || '',
    mask: defaultValue && defaultValue.length > 14 ? '(99999) 999 999' : '(9999) 999 9999',
  });

  React.useEffect(() => {
    setValue({
        value: defaultValue || '',
        mask: defaultValue && defaultValue.length > 14 ? '(99999) 999 999' : '(9999) 999 9999',
    });
  },[defaultValue]);

  const onChange = (e) => {
    let val = e.target.value;

    var newState = {
      mask: '(9999) 999 9999',
      value: val
    };

    if (val.length > 14) {
      newState.mask = '(99999) 999 999';
    }

    setValue(newState)
  }

  return (
    <div className={checkError(errors, input.name)}>
      {input.label && <label className="input-label">{input.label}</label>}
      <InputMask {...value} onChange={onChange} maskPlaceholder={null} {...input}/>
      <input type="hidden" ref={ref} value={value.value}/>
      <Alert messsage={getError(errors, input.name)} />
    </div>
  )
});

export default InputPhoneCustom;
