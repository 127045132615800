import { postData, getDataByID, getAPI_BACKEND_URL, getTakenData, getDataByUrl } from '../utils/base_service'
import { GET_LIST_CART_URL, DETAIL_CART_URL, CHANGE_STATUS_CART_URL, GET_STATICS_CART_URL } from '../constants/Config'
import { destructServerMessage, destructServerErrors } from "../helpers/error";


// GET LIST CART
export const getListCart = (params) => {
    return postData(getAPI_BACKEND_URL(GET_LIST_CART_URL), params)
        .then((res) => {
            return Promise.resolve({ data: res.data });
        })
        .catch((err) => {
            let msg = destructServerMessage(err);
            return Promise.resolve({ err: msg });
        });
};

// GET DETAIL CART
export const getDetailCart = (id) => {
    return getDataByID(getAPI_BACKEND_URL(DETAIL_CART_URL), id).then(res => {
        return Promise.resolve({ data: res.data });
    }).catch(err => {
        let msg = destructServerErrors(err);
        return Promise.reject({ err: msg });
    })
}

// CHANGE STATUS CART
export const changeStatusCartRequest = (data) => {
    return postData(CHANGE_STATUS_CART_URL, data).then(res => {
        return Promise.resolve({ data: res.data });
    }).catch(err => {
        let msg = destructServerErrors(err);
        return Promise.reject({ err: msg });
    })
}

export const getStaticsCart = () => {
    return getDataByUrl(getAPI_BACKEND_URL(GET_STATICS_CART_URL))
        .then((res) => {
            return Promise.resolve({ data: res.data })
        })
        .catch((err) => {
            let msg = destructServerErrors(err)
            return Promise.reject({
                err: msg
            })
        })
}
