import React from "react";
import { Switch, Route } from "react-router-dom";
import { Cart } from "../container/manager/Cart";

const CartRoutes = (props) => {
    const routes = [
        {
            path: "/manager/cart/:id?",
            render: (props) => <Cart {...props} />,
        },
    ];

    return (
        <div>
            <Switch>
                {routes.map((item, index) => {
                    return <Route {...item} key={index} />;
                })}
            </Switch>
        </div>
    );
};

export default CartRoutes;

