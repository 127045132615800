import {
  postData,
  putDataUrl,
  deleteByUrl,
  getDataByUrl,
  getAPI_BACKEND_URL,
} from "./../utils/base_service";
import {
  MEDIA_PERMISSION_URL,
  MEDIA_URL,
  NEW_MEDIA_FOLDER_URL,
  UPDATE_MEDIA_FOLDER_URL,
  DELETE_MEDIA_FOLDER_URL,
  SHARE_MEDIA_URL,
} from "../constants/Config";
import { destructServerMessage, destructServerErrors } from "../helpers/error";

export const getListMediaRequest = (search, id = null) => {
  return postData(getAPI_BACKEND_URL(MEDIA_URL(id)), search)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerMessage(err);
      return Promise.reject({ msg });
    });
};

// get media permission
export const getMediaPermissionRequest = (id) => {
  return getDataByUrl(getAPI_BACKEND_URL(MEDIA_PERMISSION_URL(id)))
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerMessage(err);
      return Promise.reject({ msg });
    });
};

// Share media
export const shareMediaRequest = (id, data) => {
  return postData(SHARE_MEDIA_URL(id), data)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerMessage(err);
      return Promise.reject({ msg });
    });
};

// NEW FOLDER
export const creatFolderRequest = (data) => {
  return postData(NEW_MEDIA_FOLDER_URL, data)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      err = destructServerErrors(err);
      return Promise.reject({ errors: err });
    });
};

// Rename folder
export const renameFolderRequest = (id, data) => {
  return putDataUrl(UPDATE_MEDIA_FOLDER_URL(id), data)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      err = destructServerErrors(err);
      return Promise.reject({ errors: err });
    });
};

// Delete folder
export const deleteMediafolderRequest = (id) => {
  return deleteByUrl(DELETE_MEDIA_FOLDER_URL(id))
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerMessage(err);
      return Promise.reject({ msg });
    });
};
