import React, { Component } from 'react';

class Alert extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    let { row } = this.props
    let { messsage } = this.props;
    if (!messsage) {
      return '';
    }
    return (
      <div className={row ? "invalid-feedback invalid-feedback-row " : "invalid-feedback"}> {messsage} </div>
    );
  }
}

export default Alert;
