import React, { Component } from "react";
import Pagination from "react-js-pagination";
import leftArrow from "../../assets/images/left-ar.png";
import rightArrow from "../../assets/images/right-ar.png";

class Paginate extends Component {
    changePage = (page) => {
        this.props.changePage(page);
    };

    render() {
        let { paginate } = this.props;

        if (!paginate.total) {
            return "";
        }

        let per_page = parseInt(paginate.per_page);

        return (
            <div>
                <Pagination
                    activePage={paginate.current_page}
                    itemsCountPerPage={per_page}
                    totalItemsCount={paginate.total}
                    pageRangeDisplayed={5}
                    onChange={this.changePage}
                    prevPageText={<img src={leftArrow}></img>}
                    nextPageText={<img src={rightArrow}></img>}
                    hideFirstLastPages={true}
                    itemClassPrev="paginate-nav"
                    itemClassNext="paginate-nav"
                />
            </div>
        );
    }
}

export default Paginate;
