import requester from "./requester";
import { PUBLIC_FLOOR_URL } from "../constants/Config";

export default {
  getFloors: (params = {}) =>
    requester.get(
      `${process.env.REACT_APP_ENDPOINT_API_BACKEND}/${PUBLIC_FLOOR_URL}`,
      params
    ),
};
