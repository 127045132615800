import {
  postDataWithParams,
  getDataByID,
  postData,
  putDataWithParams,
  getAPI_BACKEND_URL,
} from "./../utils/base_service";
import {
  GET_LIST_SALE_URL,
  CREATE_SALE_URL,
  DETAIL_SALE_URL,
  GET_PROJECT_SALE_URL,
  GET_CUSTOMER_SALE_URL,
} from "../constants/Config";
import * as types from "../constants/actionType";
import { destructServerErrors, destructServerMessage } from "../helpers/error";

const saleRequest = (status) => {
  return {
    type: types.SALE_REQUEST,
    status,
  };
};

// GET LIST SALE
const getSale = (data, paginate) => {
  return {
    type: types.GET_SALE_LIST,
    data,
    paginate,
  };
};

export const getSaleRequest = (search, params) => (dispatch) => {
  dispatch(saleRequest(true));
  postDataWithParams(getAPI_BACKEND_URL(GET_LIST_SALE_URL), search, params)
    .then((res) => {
      const { data, paginate } = res.data;
      const STTInPage =
        paginate.current_page === 1
          ? 0
          : (paginate.current_page - 1) * paginate.per_page;
      const convertData = data.map((item, index) => ({
        ...item,
        stt: index + 1 + STTInPage,
      }));
      dispatch(getSale(convertData, paginate));
      dispatch(saleRequest(false));
    })
    .catch((error) => {
      dispatch(saleRequest(false));
      return Promise.reject(error);
    });
};

// CREATE
export const createSaleRequest = (data) => {
  return postData(getAPI_BACKEND_URL(CREATE_SALE_URL), data)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerErrors(err);
      return Promise.reject({ err: msg });
    });
};
// GET
export const getDetailSaleRequest = (id) => {
  return getDataByID(getAPI_BACKEND_URL(DETAIL_SALE_URL), id)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerErrors(err);
      return Promise.reject({ err: msg });
    });
};
// GET LIST PRODUCT SALE
export const getListProductSale = (id, search, params) => {
  return postDataWithParams(
    getAPI_BACKEND_URL(GET_PROJECT_SALE_URL),
    search,
    params
  )
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerMessage(err);
      return Promise.resolve({ err: msg });
    });
};
// GET LIST CUSTOMER SALE
export const getListCustomerSale = (id, search, params) => {
  return putDataWithParams(
    getAPI_BACKEND_URL(GET_CUSTOMER_SALE_URL),
    id,
    search,
    params
  )
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerMessage(err);
      return Promise.resolve({ err: msg });
    });
};
