import axios from "axios";
import { localSto_token } from "../constants";
import { asyncLocalStorage } from "../helpers/functions";

export const setToken = (token) => {
  setLocalStorageToken(token);
  setHttpToken(token);
};

const setHttpToken = (token) => {
  axios.defaults.headers.common["Authorization"] = "Bearer " + token;
};

const setLocalStorageToken = async (token) => {
  await asyncLocalStorage.setItem(localSto_token, token);
};

export { axios };
