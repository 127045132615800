import axios from "axios";
import { API_URL, BASE_URL, localSto_token } from "../constants";
import fileDownload from "js-file-download";

export const getAPI_ADMIN_URL = (url) => {
  return process.env.REACT_APP_ENDPOINT_ADMIN + url;
};
export const getAPI_BACKEND_URL = (url) => {
  return process.env.REACT_APP_ENDPOINT_API_BACKEND + url;
};

export const getAPI_NEWS_URL = (url) => {
  return process.env.REACT_APP_ENDPOINT_NEWS + url;
};

// CREATE AN INSTANCE OF AXIOS
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    locate: "en",
    "Access-Control-Allow-Origin": "*",
  },
  timeout: 10000,
});

const apiInstance = axios.create({
  baseURL: API_URL,
  headers: {
    locate: "en",
    "Access-Control-Allow-Origin": "*",
  },
  timeout: 10000,
});

const config = {
  headers: { "content-type": "multipart/form-data" },
};

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (window.localStorage.getItem(localSto_token)) {
        return new Promise(function (resolve, reject) {
          originalRequest.headers["Authorization"] =
            "Bearer " + window.localStorage.getItem(localSto_token);
          resolve(axios(originalRequest));
        });
      }
    }

    return Promise.reject(error);
  }
);

axiosInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${window.localStorage.getItem(localSto_token)}`;


apiInstance.defaults.headers.common[
  "Authorization"
] = `Bearer ${window.localStorage.getItem(localSto_token)}`;

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      if (window.localStorage.getItem(localSto_token)) {
        return new Promise(function (resolve, reject) {
          originalRequest.headers["Authorization"] =
            "Bearer " + window.localStorage.getItem(localSto_token);
          resolve(axios(originalRequest));
        });
      }
    }

    return Promise.reject(error);
  }
);

const updateBaseUrl = url => axiosInstance.defaults.baseURL = url;

const getTakenData = async (url, lang = "VN") => {
  const result = await axiosInstance.get(url, {
    headers: {
      locate: lang === "VN" ? "vi" : "en",
    },
  });
  return result;
};

const getDataByID = async (url, id) => {
  const result = await axiosInstance.get(`${url}/${id}`);
  return result;
};

const getDataByParams = async (url, params) => {
  const result = await axiosInstance.get(url, { params });
  return result;
};

const postData = async (url, data) => {
  const result = await axiosInstance.post(url, data);
  return result;
};

const postDataConfig = async (url, data) => {
  const result = await axiosInstance.post(url, data, config);
  return result;
};

const putDataUrl = async (url, data) => {
  const result = await axiosInstance.put(url, data);
  return result;
};

const postDataWithParams = async (url, data, params) => {
  try {
    const result = await axiosInstance.post(url, data, { params: params });
    return result;
  } catch (e) {
    throw e;
  }
};
const putData = async (url, id, data) => {
  try {
    const result = await axiosInstance.put(`${url}/${id}`, data);
    return result;
  } catch (e) {
    throw e;
  }
};
const postDataMultipart = async (url, data) => {
  try {
    const result = await axiosInstance.post(url, data, config);
    return result;
  } catch (e) {
    throw e;
  }
};
const deleteByUrl = async (url) => {
  try {
    const result = await axiosInstance.delete(url);
    return result;
  } catch (e) {
    throw e;
  }
};
const getDataByUrl = async (url) => {
  try {
    const result = await axiosInstance.get(url);
    return result;
  } catch (e) {
    throw e;
  }
};

const downloadFileUrl = async (url, filename) => {
  try {
    const result = await axios.get(url, {
      responseType: "blob",
      "Access-Control-Allow-Origin": "*",
    });
    return fileDownload(result.data, filename);
  } catch (e) {
    throw e;
  }
};

const deleteById = async (url, id) => {
  try {
    const result = await axiosInstance.delete(`${url}/${id}`);
    return result;
  } catch (e) {
    throw e;
  }
};

const putDataWithParams = async (url, id, data, params) => {
  try {
    const result = await axiosInstance.put(`${url}/${id}`, data, {
      params: params,
    });
    return result;
  } catch (e) {
    throw e;
  }
};

export {
  axiosInstance,
  apiInstance,
  updateBaseUrl,
  getDataByID,
  getDataByUrl,
  getTakenData,
  postData,
  putDataUrl,
  getDataByParams,
  postDataConfig,
  postDataWithParams,
  putData,
  postDataMultipart,
  deleteByUrl,
  downloadFileUrl,
  deleteById,
  putDataWithParams,
};
