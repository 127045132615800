import React, { Component } from "react";
import SaleTag from "./SaleTag";
import { withRouter } from "react-router-dom";
import InputText from "../../../components/common/InputText";
import { getDetailSaleRequest } from "../../../actions/sale";
class SaleDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidMount = async () => {
    let { id } = this.props.match.params;
    if (id) {
      getDetailSaleRequest(id)
        .then((res) => {
          this.setState({ data: res.data.data, isLoading: false });
        })
        .catch((err) => {
          this.setState({ errors: err.err });
        });
    }
  };

  render() {
    let { data } = this.state;

    return (
      <>
        <div className="container-fluid container-shadow">
          <SaleTag />
          <div className="content-customer-detail mt-4">
            <div className="row">
              <div className="col-md-6 flex-wrap">
                <InputText
                  className="form-control input-form"
                  label="Họ và tên:"
                  placeholder=""
                  errors={[]}
                  type="text"
                  readOnly={true}
                  defaultValue={data && data.fullname}
                />
                <InputText
                  className="form-control input-form"
                  placeholder=""
                  label="Số điện thoại:"
                  errors={[]}
                  type="text"
                  readOnly={true}
                  defaultValue={data && data.phone}
                />
                <InputText
                  className="form-control input-form"
                  placeholder=""
                  label="Email:"
                  errors={[]}
                  type="text"
                  readOnly={true}
                  defaultValue={data && data.email}
                />
              </div>
              <div className="col-md-6 flex-wrap">
                <InputText
                  className="form-control input-form"
                  placeholder=""
                  label="Danh thu:"
                  errors={[]}
                  type="text"
                  readOnly={true}
                  defaultValue={data && data.revenue}
                />
                <InputText
                  className="form-control input-form"
                  placeholder=""
                  label="Số khách hàng tiếp cận:"
                  errors={[]}
                  type="text"
                  readOnly={true}
                  defaultValue={data && data.number_customer}
                />
                <InputText
                  className="form-control input-form"
                  placeholder=""
                  label="Số lượng sản phẩm đã bán:"
                  errors={[]}
                  type="text"
                  readOnly={true}
                  defaultValue={data && data.number_house_sold}
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SaleDetail);
