import React from "react";
import { useHistory } from "react-router-dom";
import ReactBoostrap from "react-bootstrap";
import "../../../assets/styles/manager/order.scss";
import "../../../assets/styles/manager/customer.scss";
import NewButton from "../../../components/common/NewButton";
import InputSearch from "../../../components/common/InputSearch";

const HeaderOrder = (props) => {
  const history = useHistory();
  return (
    <section className="mt-5">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div className="">
            <InputSearch/>
          </div>
          <div className="favorite_header_right">
            <NewButton onClick={() => history.push("/order/detail")} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderOrder;
