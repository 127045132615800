import React from "react";
import { Switch, Route } from "react-router-dom";
import { DashBoard } from "../container/manager/DashBoard";

const DashBoardRoutes = (props) => {
    const routes = [
        {
            path: "/manager/dashboard/:id?",
            render: (props) => <DashBoard {...props} />,
        },
    ];

    return (
        <div>
            <Switch>
                {" "}
                {routes.map((item, index) => {
                    return <Route {...item} key={index} />;
                })}{" "}
            </Switch>{" "}
        </div>
    );
};

export default DashBoardRoutes;
