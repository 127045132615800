import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import deleteIcon from "../../../assets/images/icons/iconRemoveR.svg";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { DELETE_SALE_URL } from "../../../constants/Config";
import { deleteById, getAPI_BACKEND_URL } from "../../../utils/base_service";
import NewButton from "../../../components/common/NewButton";
// import SaleCanvas from "./SaleCanvas";
import detailIcon from "../../../assets/images/detail.png";
import { getSaleRequest } from "../../../actions/sale";
import { confirmDelete, showMessage } from "../../../helpers/table";
import Can from "../../../service/Can";
import { PER_PAGE } from "../../../constants/Variable";
import TableData from "../../../components/TableData";
import Loading from "../../../assets/images/loading.gif";

const SaleTable = ({ history, getSale, sale, isLoading }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    per_page: PER_PAGE,
  });
  const base_column = [
    {
      title: "STT",
      dataIndex: "stt",
    },
    {
      title: "TÊN SALE",
      dataIndex: "fullname",
    },
    {
      title: "SỐ LƯỢNG SẢN PHẨM",
      dataIndex: "number_product",
    },
    {
      title: "DOANH SỐ",
      dataIndex: "revenue",
    },
    {
      title: "SL KHÁCH HÀNG",
      dataIndex: "number_customer",
    },
    {
      title: "SỐ CĂN ĐÃ BÁN",
      dataIndex: "number_house_sold",
    },
    {
      title: "TRẠNG THÁI",
      dataIndex: "status",
    },
    {
      title: "CHỨC NĂNG",
      fixed: "right",
      render: (data) => {
        if (data.id) {
          return (
            <div className="btn-group">
              <Can I="sale.show" a="sales">
                <span data-tip="Chi tiết sale" className="link-action mr-2">
                  <img
                    src={detailIcon}
                    width="20px"
                    className="btn-icon"
                    onClick={() => onDetailSale(data.id)}
                    alt=""
                  />
                </span>
              </Can>
              <Can I="sale.delete" a="sales">
                <span data-tip="Xóa sale" className="link-action ml-2">
                  <img
                    src={deleteIcon}
                    width="19px"
                    className="btn-icon"
                    onClick={() => onDeleteSale(data.id)}
                    alt=""
                  />
                </span>
              </Can>
              <ReactTooltip
                className="tooltip-button"
                place="bottom"
                effect="float"
              />
            </div>
          );
        }
        return null;
      },
    },
  ];

  const loadSale = async () => {
    let { page, per_page } = pagination;
    await getSale("", { page: page, per_page: per_page });
  };

  const onDetailSale = (id) => {
    history.push(`/manager/sale_list/${id}/detail`);
  };

  const onDeleteSale = (id) => {
    confirmDelete().then((res) => {
      if (res.value === true) {
        removeSaleRequest(id);
      }
    });
  };

  const removeSaleRequest = (id) => {
    deleteById(getAPI_BACKEND_URL(DELETE_SALE_URL), id)
      .then((res) => {
        showMessage("Xóa sale thành công.", true);
        loadSale();
      })
      .catch((err) => {
        showMessage("Có lỗi khi xóa!", false);
      });
  };

  const changePage = (pageNumber, e) => {
    let { per_page } = pagination;
    getSale("", { page: pageNumber, per_page: per_page });
    setPagination((prev) => ({ ...prev, page: pageNumber }));
  };
  const changePerPage = (current, size) => {
    getSale("", { page: 1, per_page: size });
    setPagination((prev) => ({ per_page: size, page: 1 }));
  };
  useEffect(() => {
    loadSale();
  }, []);

  if (isLoading) {
    return (
      <div className="main-loading">
        <img src={Loading} alt="loading..." />
      </div>
    );
  }

  return (
    <div className="container mt-5">
      <div className="row top-table">
        {/* <div className="col-md-12 top-table-title">
          <p>Quản lý Sale:</p>
        </div>
        <div className="col-md-12 top-table-title">
          <div className="row ">
            <div className="col-12 name-header-part">
              <div className="row">
                <div className="col-12 col-lg-6 col-xl-4 ">
                  <div className="customer-info">
                    <p>Tổng sales :</p>
                    <p>500 người</p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                  <div className="customer-info">
                    <p>Tổng doanh số bán được:</p>
                    <p>100 tỷ</p>
                  </div>
                </div>
                <div className="col-12 col-lg-6 col-xl-4">
                  <div className="customer-info">
                    <p>Tổng khách hàng mới:</p>
                    <p>500</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <SaleCanvas />
            </div>
          </div>
        </div> */}
        <div className="col-md-6 top-table-title">
          <p>Danh Sách Sale:</p>
        </div>
        <div className="col-md-6 d-flex">
          <div className="ml-auto d-flex">
            <Can I="sale.add" a="sales">
              <Link to="/manager/sale_list/new" className="btn-add-custom">
                <NewButton />
              </Link>
            </Can>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 ">
          <TableData
            data={sale.data}
            columns={base_column}
            pagination={{
              pageSizeOptions: [5, 10, 25, 50],
              showSizeChanger: false,
              pageSize: pagination.per_page,
              current: pagination.page,
              total: sale.paginate.total,
              onChange: changePage,
              onShowSizeChange: changePerPage,
            }}
            // Rest props
            scroll={{ x: 1500 }}
            loading={isLoading}
            rowKey={(record) => record.id}
          ></TableData>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, props) => {
  return {
    sale: state.sale,
    isLoading: state.sale.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getSale: (data, params) => dispatch(getSaleRequest(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SaleTable));
