import React, { lazy, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import LoadingScreen from "../components/Loading";

import ScrollToTopWrapper from "../components/common/ScrollToTopWrapper";
import ScrollToTopButton from "../components/common/ScrollToTop";
import Header from "../components/Header";
import { NotificationContainer } from "react-notifications";

const HomePageRouter = () => {
  return (
    <>
      <ScrollToTopWrapper>
        <Header />
        <NotificationContainer />
        <Suspense fallback={<LoadingScreen />}>
          <Switch>
            {routes.map((route, i) => {
              const { path, component, exact } = route;
              const Component = component;
              return (
                <Route
                  key={i}
                  path={path}
                  exact={exact}
                  render={(props) => <Component {...props} />}
                />
              );
            })}
          </Switch>
        </Suspense>
        <ScrollToTopButton display={true} />
      </ScrollToTopWrapper>
    </>
  );
};

const routes = [
  {
    exact: true,
    path: "/",
    component: lazy(() => import("../container/Home/index")),
  },
  {
    exact: true,
    path: "/overview",
    component: lazy(() => import("../container/Overview/overview")),
  },
  {
    exact: true,
    path: "/project",
    component: lazy(() => import("../container/Project/project")),
  },
  {
    exact: true,
    path: "/availability",
    component: lazy(() => import("../container/Availability/availability")),
  },
  {
    exact: true,
    path: "/product/:id/detail",
    component: lazy(() => import("../container/DetailProduct")),
  },
  {
    exact: true,
    path: "/order/detail",
    component: lazy(() => import("../container/manager/OrderDetails")),
  },
  {
    exact: true,
    path: "/location",
    component: lazy(() => import("../container/Location")),
  },
  {
    exact: true,
    path: "/explore",
    component: lazy(() => import("../container/Explore")),
  },
  {
    exact: true,
    path: "/floorplans*",
    component: lazy(() => import("../container/Floor")),
  },
  {
    exact: true,
    path: "/team",
    component: lazy(() => import("../container/Team/team")),
  },
  {
    exact: true,
    path: "/contact",
    component: lazy(() => import("../container/Contact/Contact")),
  },
  {
    exact: true,
    path: "/compare/:id",
    component: lazy(() => import("../container/Compare/compare")),
  },
  {
    exact: true,
    path: "/compare",
    component: lazy(() => import("../container/Compare/compare")),
  },
  {
    exact: true,
    path: "/compare/detail_product/:id",
    component: lazy(() => import("../container/DetailProduct")),
  },
  {
    exact: true,
    path: "/floorplans/2d",
    component: lazy(() => import("../container/Floor/ViewFloor2D")),
  },
  {
    exact: true,
    path: "/amenities",
    component: lazy(() => import("../container/Amenities")),
  },
  {
    exact: true,
    path: "/favorite",
    component: lazy(() => import("../container/Favorite")),
  },
  {
    exact: true,
    path: "/account",
    component: lazy(() => import("../container/Account")),
  },
  {
    exact: true,
    path: "/news",
    component: lazy(() => import("../container/News")),
  },
  {
    exact: true,
    path: "/news/:id/detail",
    component: lazy(() => import("../container/News/NewsDetail")),
  },
];

export default HomePageRouter;
