import { localSto_token, localSto_rememberLogin } from "../constants";
import * as types from "./../constants/actionType";
import { setToken } from "../helpers/auth";
import { asyncLocalStorage } from "../helpers/functions";
import { getAPI_BACKEND_URL } from "../utils/base_service";
import {
  postDataWithParams,
  getDataByID,
  postData,
  putData,
  getTakenData,
} from "../utils/base_service";
import {
  USER_SELECT_URL,
  GET_LIST_USER_URL,
  NEW_USER_URL,
  DETAIL_USER_URL,
  UPDATE_USER_URL,
} from "../constants/Config";
import { destructServerErrors } from "../helpers/error";

const setUser = (data) => {
  setTokenUser(data.access_token, data.remember_login);
  return {
    type: types.SET_USER,
    payload: data,
  };
};
const setTokenUser = async (token, isRememberLogin = false) => {
  if (token) {
    setToken(token);
    if (isRememberLogin)
      await asyncLocalStorage.setItem(localSto_rememberLogin, isRememberLogin);
  } else {
    localStorage.removeItem(localSto_token);
    localStorage.removeItem(localSto_rememberLogin);
  }
};
const logOutUser = () => {
  // delete local storage
  setTokenUser(null);
  // eslint-disable-next-line no-restricted-globals
  location.reload();
  return {
    type: types.LOG_OUT,
  };
};
const getUser = () => {
  return getTakenData(getAPI_BACKEND_URL("/me"))
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
};

const setLoadingUser = (isLoading) => (dispatch) => {
  dispatch({
    type: types.SET_LOADING_USER,
    payload: isLoading,
  });
};
const clearAuth = () => (dispatch) => {
  setToken(null);
  localStorage.removeItem(localSto_rememberLogin);
};

const initUserFromToken = () => async (dispatch) => {
  dispatch(setLoadingUser(true));
  const token = await asyncLocalStorage.getItem(localSto_token);
  const isRemmemberLogin = await asyncLocalStorage.getItem(
    localSto_rememberLogin
  );
  if (token && isRemmemberLogin) {
    getUser()
      .then(async (res) => {
        let user = res.data.data;
        await dispatch(setUser({ ...user, access_token: token }));
        dispatch(setLoadingUser(false));
      })
      .catch((error) => {
        if (error.response) {
          dispatch(clearAuth());
        }
        dispatch(setLoadingUser(false));
      });
  } else {
    dispatch(setLoadingUser(false));
    clearAuth();
  }
};

const getUserRequest = (search, params) => (dispatch) => {
  dispatch(userRequest(true));
  postDataWithParams(GET_LIST_USER_URL, search, params)
    .then((res) => {
      dispatch(getUser(res.data, res.paginate));
      dispatch(userRequest(false));
    })
    .catch((error) => {
      dispatch(userRequest(false));
      return Promise.reject(error);
    });
};

const resetState = () => {
  return {
    type: types.RESET_USER,
  };
};
// CREATE
const createUserRequest = (data) => {
  return postData(NEW_USER_URL, data)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerErrors(err);
      return Promise.reject({ err: msg });
    });
};
// UPDATE
const updateUserRequest = (id, data) => {
  return putData(UPDATE_USER_URL, id, data)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerErrors(err);
      return Promise.reject({ err: msg });
    });
};
// ----
const userRequest = (status) => {
  return {
    type: types.USER_REQUEST,
    status,
  };
};
// GET
const getDetailUserRequest = (id) => {
  return getDataByID(DETAIL_USER_URL, id)
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerErrors(err);
      return Promise.reject({ err: msg });
    });
};
// GET USER SELECT
const getUserSelectRequest = () => {
  return getTakenData(getAPI_BACKEND_URL(USER_SELECT_URL))
    .then((res) => {
      return Promise.resolve({ data: res.data });
    })
    .catch((err) => {
      let msg = destructServerErrors(err);
      return Promise.reject({ err: msg });
    });
};

export {
  setUser,
  logOutUser,
  initUserFromToken,
  clearAuth,
  getUserRequest,
  getUserSelectRequest,
  getDetailUserRequest,
  updateUserRequest,
  createUserRequest,
  resetState,
  setLoadingUser,
};
