import React from "react";
// import { Link } from "react-router-dom";

const BlockTableListBottom = (props) => {
  const { SalesPolicyStatistic } = props;

  return (
    <div className="block-table-list block-table-list-bottom">
      <div className="block-title">
        <p className="title-list">Chính sách bán hàng</p>
      </div>
      <div className="block-list">
        <div className="table-dashboard-maintenance">
          <table className="table">
            <thead>
              <tr>
                <th>Tên chính sách</th>
                <th>Đối tượng áp dụng</th>
                <th>Thời gian áp dụng</th>
                <th>Thời gian kết thúc</th>
                <th>Files</th>
              </tr>
            </thead>
            <tbody>
              {SalesPolicyStatistic?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item?.name}</td>
                    <td>{item?.distributor?.name}</td>
                    <td className="text-center">{item?.date_apply}</td>
                    <td className="text-center">{item?.expriration_date}</td>
                    <td>
                      <a href={item?.file?.path} download>
                        {item?.file?.filename}
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-center">
          {/* <Link to="/sales_policy" className="view-more-detail">
            Xem chi tiết
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default BlockTableListBottom;
