import React from "react";
import { useHistory } from "react-router-dom";
import menu1 from "../../../assets/images/menu.svg";
import menu2 from "../../../assets/images/menu2.svg";
import InputSearch from "../../../components/common/InputSearch";

const HeaderFavorite = (props) => {
  return (
    <section className="favorite_header">
      <div className="container">
        <div className="d-flex justify-content-between align-items-center">
          <div className="favorite_header_left">
            <InputSearch />
          </div>
          <div className="favorite_header_right">
            <img src={menu1} alt="" onClick={() => props.handleSetMenu(1)} />
            <img src={menu2} alt="" onClick={() => props.handleSetMenu(2)} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeaderFavorite;
