import React from 'react'
import { ref } from 'yup'
import { checkError, getError } from '../../helpers/error'
import Alert from './Alert'

const SelectCustom = React.forwardRef((props, ref) => {
    const { errors, data, key_value, key_label, label, include_blank, defaultValue, classNameCustom, ...input } = props
    return (
        <div className={` ${checkError(errors, input.name)} ${classNameCustom ? classNameCustom : ''}`}>
            { label && <label className="input-label">{label}</label>}
            <select {...input} ref={ref}>
                {defaultValue ? (<option value={defaultValue[key_value]}>{defaultValue[key_label]}</option>)
                    : (
                        include_blank && <option value="">{include_blank}</option>
                    )
                }
                {
                    data.map((item, index) => {
                        return (
                            defaultValue ?
                                ((item[key_label] != defaultValue[key_label]) && <option key={index} value={item[key_value]}>{item[key_label]}</option>)
                                : (<option key={index} value={item[key_value]}>{item[key_label]}</option>)
                        )
                    })
                }
            </select>
            <Alert messsage={getError(errors, input.name)} />
        </div>
    )
})

export default SelectCustom
