import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { ref } from 'yup'
import { getFormDataFromRef } from '../../../helpers/form'
import { createCustomerRequest } from '../../../actions/customer'
import { showMessage } from '../../../helpers/table';
import InputText from '../../../components/common/InputText'
import InputPhone from '../../../components/common/InputPhone'
import { withRouter } from 'react-router-dom'
import SelectCustom from '../../../components/common/SelectCustom';
import InputDate from '../../../components/common/InputDate';
import { CUSTOMER_STATUSES } from '../../../constants';

class AddCustomer extends Component {
  constructor(props) {
    super(props)
    this.message = null

    this.state = {
      errors: {}
    }
  }

  onCreateCustomer = (e) => {
    e.preventDefault();
    var formData = new FormData(e.target);
    let phone = formData.get('phone');
    phone = phone
      .replace(/\D/g, '')
      .replace(/(\d{1,4})(\d{1,3})?(\d{1,3})?/g, function (txt, f, s, t) {
        if (t) {
          return `${f}${s}${t}`
        } else if (s) {
          return `${f}${s}`
        } else if (f) {
          return `${f}`
        }
      });
    formData.set('phone', phone);

    createCustomerRequest(formData)
      .then(res => {
        showMessage(res.data.message, true);
        this.props.onSuccess();
        this.props.history.push("/manager/customer");
        this.setState({ errors: {} });
      })
      .catch(err => {
        // showMessage(err.data, false)
        this.setState({ errors: err.err });
      })
  }

  render() {
    let { errors } = this.state
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal_header">
          <span className="modal_header_title">Thêm khách hàng</span>
          <span onClick={this.props.onHide}>x</span>
        </div>
        <form onSubmit={this.onCreateCustomer}>
          <div className="modal_body">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <InputText
                    className="form-control input-form"
                    placeholder="Nguyễn Văn A"
                    name="name"
                    ref="name"
                    label="Tên:"
                    errors={errors}
                    type="text"
                  />
                  <InputPhone
                    name="phone"
                    ref="phone"
                    label="Số điện thoại:"
                    type="text"
                    className="form-control input-form"
                    placeholder="0909 090 090"
                    errors={errors}
                  />
                  <InputDate 
                    className="form-control input-form input-date"
                    errors={errors} 
                    name="birthday" 
                    placeholder="Chọn ngày"
                    format='YYYY-MM-DD' 
                    label="Ngày sinh:" 
                  />
                </div>
                <div className="col-md-6">
                  <InputText
                    className="form-control input-form"
                    placeholder="123 Phùng Hưng"
                    name="address"
                    ref="address"
                    label="Địa chỉ:"
                    errors={errors}
                    type="text"
                  />
                  <InputText
                    className="form-control input-form"
                    placeholder="nguyenvana@gmail.com"
                    name="email"
                    ref="email"
                    label="Email:"
                    errors={errors}
                    type="text"
                  />
                  <InputText
                    className="form-control input-form"
                    placeholder="Đà Nẵng"
                    name="home town"
                    ref="home town"
                    label="Quê quán:"
                    errors={errors}
                    type="text"
                  />
                  <SelectCustom
                    className="form-control input-form"
                    include_blank="Chọn trạng thái"
                    name="status"
                    ref="status"
                    label="Trạng trái:"
                    data={CUSTOMER_STATUSES}
                    errors={errors}
                    key_value="value"
                    key_label="label"
                  />
                </div>
              </div>
              <div className="modal_body_item">
                <p>Ghi chú:</p>
                <textarea type="text" placeholder="Ghi chú" />
              </div>
            </div>
          </div>
          <div className="modal_footer row justify-content-center">
            <button type="button" onClick={this.props.onHide}>Huỷ</button>
            <button type="submit">Lưu</button>
          </div>
        </form>
      </Modal>
    )
  }
}

export default withRouter(AddCustomer)
