import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
// import CustomerTable from "./../container/Customer/CustomerTable";
// import CustomerDetail from "./../container/Customer/CustomerDetail";
// import CustomerFavorite from "./../container/Customer/CustomerFavorite";
// import CustomerSale from "./../container/Customer/CustomerSale";
// import CustomerNew from "./../container/Customer/CustomerNew";
// import CustomerEdit from "./../container/Customer/CustomerEdit";
// import AuthRoute from "./AuthRoute";
import { Customer } from '../container/manager/Customer'

// const routes = [
//   {
//     path: "/customer",
//     component: () => <CustomerTable />,
//     exact: true,
//     meta: {
//       action: "customer.list",
//       subject: "customers",
//     },
//   },
//   {
//     path: "/customer/:id/detail",
//     component: () => <CustomerDetail />,
//     exact: true,
//     meta: {
//       action: "customer.show",
//       subject: "customers",
//     },
//   },
//   {
//     path: "/customer/:id/favorite",
//     component: () => <CustomerFavorite />,
//     exact: true,
//   },
//   {
//     path: "/customer/:id/sale",
//     component: () => <CustomerSale />,
//     exact: true,
//   },
//   {
//     path: "/customer/new",
//     component: () => <CustomerNew />,
//     exact: true,
//     meta: {
//       action: "customer.add",
//       subject: "customers",
//     },
//   },
//   {
//     path: "/customer/:id/edit",
//     component: () => <CustomerEdit />,
//     exact: true,
//     meta: {
//       action: "customer.edit",
//       subject: "customers",
//     },
//   },
// ];

// class CustomerRoutes extends Component {
//   render() {
//     if (typeof window !== "undefined") {
//       return (
//         <React.Fragment>
//           <Switch>
//             {routes.map((route) => (
//               <AuthRoute key={route.path} {...route} />
//             ))}
//           </Switch>
//         </React.Fragment>
//       );
//     }
//   }
// }


const CustomerRoutes = (props) => {
  const routes = [
    {
      path: "/manager/customer/:id?",
      render: (props) => <Customer {...props} />,
    },
  ];

  return (
    <div>
      <Switch>
        {routes.map((item, index) => {
          return <Route {...item} key={index} />;
        })}
      </Switch>
    </div>
  );
};

export default CustomerRoutes;
