import React from "react";
import iconValue from "../../../assets/images/icons/value.svg";
import iconApproach from "../../../assets/images/icons/approach.svg";
import iconOnlineBusiness from "../../../assets/images/icons/online-business.svg";
import iconBestPrice from "../../../assets/images/icons/best_price.svg";
import { Link } from "react-router-dom";

const BlockTableList = (props) => {
  const { CustomerStatistic, Customer } = props;

  const onShowDetail = (item) => {
    console.log(item);
  }

  return (
    <div className="block-table-list">
      <div className="block-title">
        <p className="title-list">Khách hàng</p>
      </div>
      <div className="block-item">
        <div className="row ml-0 mr-0">
          <div className="col-3">
            <div className="item-detail item-detail-1">
              <p>Khách hàng mới</p>
              <span>
                {Customer?.customer_new} <img src={iconValue} alt="" />
              </span>
            </div>
          </div>
          <div className="col-3">
            <div className="item-detail item-detail-2">
              <p>Tiếp cận</p>
              <span>
                {Customer?.customer_approach} <img src={iconApproach} alt="" />
              </span>
            </div>
          </div>
          <div className="col-3">
            <div className="item-detail item-detail-3">
              <p>Đang tư vấn</p>
              <span>
                {Customer?.customer_consoulting}{" "}
                <img src={iconOnlineBusiness} alt="" />
              </span>
            </div>
          </div>
          <div className="col-3">
            <div className="item-detail item-detail-4">
              <p>Chốt Sale</p>
              <span>
                {Customer?.customer_closing_sale}{" "}
                <img src={iconBestPrice} alt="" />
              </span>
            </div>
          </div>
        </div>
        <p className="title-list title-list-table">Khách hàng mới</p>
      </div>
      <div className="block-list">
        <div className="table-dashboard-maintenance">
          <table className="table">
            <thead>
              <tr>
                <th>Tên chính sách</th>
                <th>Đang theo dõi</th>
                <th>Trạng thái</th>
                <th>Chi tiết</th>
              </tr>
            </thead>
            <tbody>
              {CustomerStatistic?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>{item.full_name}</td>
                    <td className="text-center">{item.favorite}</td>
                    <td className="text-center">
                      <button
                        className={`btn-new-list btn-new-list-${item.status}`}
                      >
                        {item.status_lable}
                      </button>
                    </td>
                    <td className="text-center">
                    <button className="btn btn-custom text-white" onClick={() => onShowDetail(item)}>
                      Detail
                    </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="text-center">
          <Link to="/manager/customer" className="view-more-detail">
            Xem chi tiết
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BlockTableList;
