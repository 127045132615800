import React from "react";
import LoadingGif from "../assets/images/loading.gif";

const Loading = ({ className }) => {
  return (
    <div className={`loading_wrapper ${className ? className : ''}`}>
      <img src={LoadingGif} alt="loading..." />
    </div>
  );
};

export default Loading;
