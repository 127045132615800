import { Ability } from "@casl/ability";
import store from "../store/store";

// Defines how to detect object's type
function subjectName(item) {
  if (!item || typeof item === "string") {
    return item;
  }
  return item.__type;
}

const ability = new Ability([], { subjectName });

store.subscribe(async () => {
  let { data } = await store.getState().user;

  if (data) {
    const rules = data.permissions;
    ability.update(rules);
  }
});

export default ability;
