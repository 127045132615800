import React from "react";
import "../../../assets/styles/manager/favorite.scss";
import MainFavorite from "./MainFavorite";

const Favorite = () => {
  return (
    <>
      <MainFavorite />
    </>
  );
};

export default Favorite;
