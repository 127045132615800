import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Slider } from 'antd'
import { FrownOutlined, SmileOutlined } from '@ant-design/icons';
import { getAPI_BACKEND_URL, getDataByID, getTakenData, postData } from '../../../utils/base_service';
import { GET_FLOOR_BUILDING, GET_LIST_CART_URL } from '../../../constants/Config';
import { Select } from 'antd'
import { getListCart } from '../../../actions/cart'
class FilterCart extends Component {
    constructor(props) {
        super(props)
        this.message = null
        this.search = ""
        this.state = {
            errors: {},
            value: "Choose the number of rooms",
            dataBedRoom: [],
            dataBathRoom: [],
            dataPrice: null,
            dataArea: null,
            dataFilter: []
        }
    }


    handleChangeArea = dataArea => {
        this.setState({ dataArea });
    };
    handleChangePrice = dataPrice => {
        this.setState({ dataPrice });

    };
    handleChangeBedRoom = dataBedRoom => {
        this.setState({
            dataBedRoom
        })
    }

    handleChangeBathRoom = dataBathRoom => {
        this.setState({ dataBathRoom })
    }

    handleClearAllFilter = () => {
        this.setState({
            dataBedRoom: [],
            dataBathRoom: [],
            dataPrice: '',
            dataArea: '',
        })
    }



    render() {
        const Option = Select
        const { max, min } = this.props;
        const { dataBedRoom, dataBathRoom, dataArea, dataPrice } = this.state
        return (
            <div className={`cart_filter ${this.props.className}`}>
                <div className="cart_filter_title">Filter Results</div>
                <div className="cart_filter_select_group p-2 d-flex">
                    <div className="cart_filter--items d-flex">
                        <label>BedRoom</label>
                        <Select onChange={(e) => this.handleChangeBedRoom(e)} value={dataBedRoom} className="" mode="multiple" >
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="All">All</Option>
                        </Select>
                    </div>
                    <div className="cart_filter--items d-flex">
                        <label>BathRooms</label>
                        <Select onChange={(e) => this.handleChangeBathRoom(e)} value={dataBathRoom} mode="multiple" className="">
                            <Option value="1">1</Option>
                            <Option value="2">2</Option>
                            <Option value="3">3</Option>
                            <Option value="4">4</Option>
                            <Option value="All">All</Option>
                        </Select>
                    </div>
                    <div className="form-group">
                        <label>AREA (m2)</label>
                        <div className="icon-wrapper">
                            <span>{min}</span>
                            <Slider {...this.props} onChange={this.handleChangeArea} value={dataArea} />
                            <span>{max}</span>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>PRICE</label>
                        <div className="icon-wrapper">
                            <span>{min}</span>
                            <Slider {...this.props} onChange={this.handleChangePrice} value={dataPrice} />
                            <span>{max}</span>
                        </div>
                    </div>
                </div>
                <div className="cart_filter--group-btn">
                    <button onClick={this.handleClearAllFilter} className="btn-custom text-uppercase">clear all</button>
                    <button onClick={() => this.props.onSubmitFilter(dataBedRoom, dataBathRoom, dataArea, dataPrice)} className="btn-custom text-uppercase">apply filter</button>
                </div>
            </div>
        )
    }
}

export default withRouter(FilterCart)
