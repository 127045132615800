import dateFormat from "dateformat";
import { postDataMultipart } from "../utils/base_service";
import { UPLOAD_URL, UPLOAD_FILE } from "../constants/Config";
import { destructServerErrors } from "./error";

export const getFormDataFromRef = (refs) => {
  let data = [];
  data = Object.keys(refs).map((item, index) => {
    return {
      ...data,
      [item]:
        refs[item].type === "checkbox" ? refs[item].checked : refs[item].value,
    };
  });

  return Object.assign({}, ...data);
};

export const getCheckBoxWithNameFromRef = (refs, name) => {
  let data = [];
  data = Object.keys(refs)
    .map((item) => {
      return item.startsWith(name) && refs[item].checked
        ? refs[item].value
        : null;
    })
    .filter(Boolean);

  return data;
};

export const formatDate = (date, format = "dd-mm-yyyy") => {
  if (!date) {
    return "";
  }
  date = new Date(date);
  return dateFormat(date, format);
};

export const uploadAvatar = async (file) => {
  let default_img = "assets/img/icons/image.svg";
  if (file) {
    let formData = new FormData();
    formData.append("file", file);
    return await postDataMultipart(UPLOAD_URL, formData)
      .then(({ data: { file } }) => {
        return Promise.resolve({
          status: true,
          file: file,
          error_upload: {},
        });
      })
      .catch((err) => {
        let err_upload = destructServerErrors(err);
        return Promise.reject({
          status: false,
          file: default_img,
          error_upload: err_upload,
        });
      });
  }
};

export const uploadDocument = async (file) => {
  if (file) {
    let formData = new FormData();
    formData.append("file", file);
    return await postDataMultipart(UPLOAD_URL, formData)
      .then((res) => {
        return Promise.resolve({
          data: res.data,
        });
      })
      .catch((err) => {
        let err_upload = destructServerErrors(err);
        return Promise.reject({
          error_upload: err_upload,
        });
      });
  }
};

export const sizeOf = (bytes) => {
  if (bytes === 0) {
    return "0.00 B";
  }
  var e = Math.floor(Math.log(bytes) / Math.log(1024));
  return (
    (bytes / Math.pow(1024, e)).toFixed(2) + " " + " KMGTP".charAt(e) + "B"
  );
};

export const uploadFile = async (file) => {
  if (file) {
    let formData = new FormData();
    formData.append("file", file);
    return await postDataMultipart(UPLOAD_FILE, formData)
      .then((res) => {
        return Promise.resolve({
          data: res.data,
        });
      })
      .catch((err) => {
        let err_upload = destructServerErrors(err);
        return Promise.reject({
          error_upload: err_upload,
        });
      });
  }
};
