import React, { Component, memo, useState } from "react";
import { Link } from 'react-router-dom';
import MaskAcreage from "../../../assets/images/icons/MaskAcreage.svg";
import MaskBedroom from "../../../assets/images/icons/MaskBedroom.svg";
import MaskBlock from "../../../assets/images/icons/MaskBlock.svg";
import MaskBuilding from "../../../assets/images/icons/MaskBuilding.svg";
import MaskDirection from "../../../assets/images/icons/MaskDirection.svg";
import MaskPrice from "../../../assets/images/icons/MaskPrice.svg";
import { getDetailCart, changeStatusCartRequest } from "../../../actions/cart";
import { moneyFormat } from "../../../helpers/table";
import { GET_LIST_CUSTOMER_URL, PRODUCT_STATUS } from "../../../constants/Config";
import { showMessage } from "../../../helpers/table";
import { Modal, Button, Input, Select } from 'antd'
import { getAPI_BACKEND_URL, postData, postDataWithParams } from "../../../utils/base_service";
import FormUser from './FormUser';

const SelectBox = memo(({ data, onAdd, FormComponent, ...props }) => {
  const { Option } = Select
  const [open, setOpen] = useState(false);

  const openForm = (e) => {
    setOpen(!open);
  }

  const closeForm = (e) => {
    setOpen(false);
  }

  const onSuccess = (res) => {
    onAdd(res);
  }
  
  return (
    <div className="d-flex">
      <Select {...props} >
        {
          data && data.map((item, index) => (
            <Option key={index} value={item.id}>{item.name}</Option>
          ))
        }
      </Select>
      <div className="dropdown">
          <button className="btn btn-success btn-sm" onClick={openForm}>
            <span className="lnr lnr-plus-circle"></span>
          </button>
          <div className={`dropdown-body ${open ? 'show' : ''}`}>
              <FormComponent onCancel={closeForm} onSuccess={onSuccess}/>
          </div>
      </div>
    </div>
  );
})

class DetailBlock extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.open,
            data: {},
            dataCustomer: [],
            showInputCustomer: false,
            errors: {},
            dataProduct: {},
        };
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.open !== prevState.open) {
            if (nextProps.open) {
            }
            return { open: nextProps.open };
        } else return null;
    }

    openModal = (e) => {
        e.preventDefault();
        this.setState({ open: true });
    };

    closeModal = (e) => {
        this.props.detailBlock(e, false);
        this.setState({ open: false });
    };

    componentDidMount = () => {
        let id = this.props.product_id;

        if (id) {
            getDetailCart(id)
                .then((res) => {
                    this.setState({ data: res.data.data });
                })
                .catch((err) => {
                    this.setState({ errors: err.err });
                });
        }
        
        this.getListCustomers()
    };

    getListCustomers = () => {
        postData(getAPI_BACKEND_URL(GET_LIST_CUSTOMER_URL))
            .then((res) => {
                this.setState({ dataCustomer: res.data.data })
            })
            .catch((err) => {
                this.setState({ errors: err.err })
            })
    }

    onAddCustomer = (customer) => {
      let customers = this.state.dataCustomer;
      customers.push(customer);
      this.setState({ dataCustomer: customers });
    }

    updateStatusProduct = (e, status, product_id) => {
        postDataWithParams(getAPI_BACKEND_URL(PRODUCT_STATUS), {
            "status": 3,
            "product_id": product_id,
            "description": this.dataNotes
        })
            .then((res) => {
                this.closeModal(e);
                showMessage("Cập nhập trạng thái giỏ hàng thành công!", true);
                this.setState({ dataProduct: res.data })
            })
            .catch((err) => {
                this.setState({
                    errors: err.err
                })
            })
    }

    onKeepSeat = (e) => {
        e.preventDefault();
        this.setState({
            showInputCustomer: true,
        });
    };

    onDeposit = (e) => {
        e.preventDefault();
        this.setState({
            showInputCustomer: true,
        });
    };

    onSubmitForm = (e, status) => {
        e.preventDefault();
        var formData = {
            customer_id: this.customer_id,
            product_id: this.props.product_id,
            status: status,
        };
        changeStatusCartRequest(formData)
            .then((res) => {
                this.closeModal(e);
                showMessage("Cập nhập trạng thái giỏ hàng thành công!", true);
                this.props.onCloseForm();
            })
            .catch((err) => {
                this.setState({ errors: err.err });
            });
    };

    onChangeCustomer = (customer_id) => {
        this.customer_id = customer_id;
    };
    handleChangeNotes = (e) => {
        this.dataNotes = e.target.value
    }

    render() {
        const { TextArea } = Input;
        const { open, data, dataCustomer } = this.state;
        const { product_id } = this.props;

        return (
            <div className="custom-popup custom-popup-cart">
                <Modal
                    title={`Detail ${data.product_code}`}
                    visible={open}
                    onCancel={(e) => this.closeModal(e)}
                    width={1000}
                    footer={[
                        <Link className="ant-btn ant-btn-primary btn-success" to={`/product/${product_id}/detail`}>DETAIL</Link>,
                        <Button key="back" onClick={(e) => this.closeModal(e)}>CANCEL</Button>,
                        <Button key="submit" type="primary" onClick={(e) => this.updateStatusProduct(e, 3, data.id)}>BOOK</Button>,
                    ]}
                >
                    <div className="modal-custom-cart d-flex">
                        <div className="modal-custom-cart-statics">
                            <div className="d-flex justify-content-around">
                                <p className="text-in-block col-md-3">
                                    <img src={MaskAcreage} className="mr-2" alt="" />
                                Acreage:
                                {data &&
                                        data.template_product &&
                                        data.template_product.area_inside}
                                M²
                            </p>
                                <p className="text-in-block col-md-3">
                                    <img src={MaskPrice} className="mr-2" alt="" />
                                Price:
                                {moneyFormat(
                                        data && data.template_product && data.template_product.price
                                    )}
                                VNĐ
                            </p>
                                <p className="text-in-block col-md-3">
                                    <img src={MaskBedroom} className="mr-2" alt="" />
                                Beds:
                                {data &&
                                        data.template_product &&
                                        data.template_product.number_bedroom}
                                </p>

                            </div>
                            <div className="d-flex justify-content-around">
                                <p className="text-in-block col-md-3">
                                    <img src={MaskDirection} className="mr-2" alt="" />
                                Direction:
                                {data &&
                                        data.template_product &&
                                        data.template_product.direction &&
                                        data.template_product.direction.name}
                                </p>

                                <p className="text-in-block col-md-3">
                                    <img src={MaskBuilding} className="mr-2" alt="" />
                                Building:
                                {data && data.building && data.building.name}
                                </p>
                                <p className="text-in-block col-md-3">
                                    <img src={MaskBlock} className="mr-2" alt="" />
                                Block:
                                {data && data.block && data.block.name}
                                </p>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="modal-custom-cart-select d-flex justify-content-between">
                        {/* <div className="modal-custom-cart-select_items d-flex w-25 align-items-center">
                            <label>Status:</label>
                            <Select className="item w-100 ml-2">
                                <Option>Open for Sale</Option>
                                <Option>Reserve</Option>
                                <Option>Deposit</Option>
                                <Option>Payment</Option>
                            </Select>
                        </div> */}
                        <div className="modal-custom-cart-select_items mb-3">
                            <label className="d-block">Khách hàng:</label>
                            <SelectBox 
                              showSearch 
                              style={{ width: 200 }}
                              data={dataCustomer} 
                              className="item" 
                              placeholder="Chọn khách hàng" 
                              FormComponent={FormUser} 
                              onAdd={(res) => this.onAddCustomer(res)}
                            />
                        </div>
                    </div>
                    <div className="modal-custom-cart_notes">
                        <label>Notes:</label>
                        <TextArea rows={6} placeholder="Lorem ipsum dolor sit amet, consectetur adipiscing elit" onChange={this.handleChangeNotes} value={this.dataNotes} />
                    </div>
                </Modal>
            </div>
        );
    }
}

export default DetailBlock;
