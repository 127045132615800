import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import switchUrl from '../../../hocs/switchUrl';
import { API_URL, BASE_URL, PRODUCT_BOOKED_STATUS } from '../../../constants';
import { useCartProducts } from '../../../hooks/cart';
import { useHistory } from 'react-router-dom';
import { Table, Tag } from 'antd';
import { formatMoney, objectToQuery } from '../../../utils';
import { useQuery, usePagination } from '../../../hooks';
import classNames from 'classnames';
import DevideCart from './DevideCart';
import FilterProduct from './FilterProduct'

const Distribution = (props) => {
  let params = useQuery();
  params.per_page = 10;
  const history = useHistory();
  const [selectedProducts, setSelectedProduct] = useState([]);
  const [selectedKeys, setSelectedKey] = useState([]);
  const [{ data, loading, paginate }, handleSubmit] = useCartProducts();
  const pagination = usePagination(paginate);
  const { t } = useTranslation();

  const tableCloumns = [
    {
      title: 'Mã sản phẩm',
      dataIndex: 'product_code',
      key: 'product_code',
    },
    {
      title: 'Loại căn',
      dataIndex: 'template_product',
      key: 'template_product',
      render: res => res?.name,
    },
    {
      title: 'Tầng',
      dataIndex: 'floor',
      key: 'floor',
      render: res => res?.name,
    },
    {
      title: 'Giá bán',
      dataIndex: 'current_price',
      key: 'current_price',
      render: num => formatMoney(num),
    },
    {
      title: 'Trạng thái',
      dataIndex: null,
      key: 'status',
      render: ({ color, status_lable }) => <Tag color={color}>{status_lable}</Tag>,
    },
    {
      title: 'Saler',
      dataIndex: 'salers',
      key: 'salers',
      render: (res) => res.map(o => o.name).join(', '),
    },
  ];

  useEffect(() => {
    handleSubmit(params);
  }, []);

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedProduct(selectedRows);
      setSelectedKey(selectedRowKeys);
    },
    getCheckboxProps: (record) => {
      return {
        name: 'product_id',
        key: record.id,
        disabled: record.status >= PRODUCT_BOOKED_STATUS
      }
    },
  };

  const handleTableChange = (pagination) => {
    params.page = pagination.current    
    history.push({
      pathname: history.location.pathname,
      search: objectToQuery(params)
    });

    handleSubmit(params);
  }

  const resetSelected = useCallback(()=>{
    setSelectedProduct([]);
    setSelectedKey([]);
  }, [])

  // Reload table when assign success
  const onDevideSuccess = () => {
    handleSubmit(params);
    resetSelected();
  }

  const handleFitler = (values) => {
    params = {...params, ...values}
    history.push({
      pathname: history.location.pathname,
      search: objectToQuery(params)
    });
    resetSelected();
    handleSubmit(params);
  }

  return (
    <div className="distribution">
      <div className="container">

        <div className="distribution-filter">
          <FilterProduct t={t} loading={loading} handleSubmit={handleFitler}/>
        </div>

        <div className="distribution-table">
          <div className={classNames('distribution-action', { show: selectedKeys.length > 0 })}>
            {
              selectedKeys.length > 0 && <DevideCart t={t} products={selectedProducts} onSuccess={onDevideSuccess}/>
            }
            <button className="btn btn-outline-primary btn-sm ml-3" onClick={resetSelected}>
              {t('buttons.unselect')}
            </button>
            <span className="text-success ml-3">{t('buttons.selected', { num: selectedKeys.length })}</span>
          </div>
          <Table
            rowSelection={{
              ...rowSelection,
              type: 'checkbox',
              selectedRowKeys: selectedKeys
            }}
            rowKey={record => record.id}
            columns={tableCloumns}
            pagination={pagination}
            dataSource={data}
            loading={loading}
            onChange={handleTableChange}
          />
        </div>
      </div>
    </div>
  );
}

export default switchUrl(API_URL, BASE_URL)(Distribution);