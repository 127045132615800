import React from "react";
import Can from "../service/Can";
import store from "../store/store";
import { Route, Redirect } from "react-router-dom";
import { checkIsAdmin } from "../helpers/functions";

const ManagerGuard = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        const { user } = store.getState();
        const isAdmin = user.data && user.data.position_id;
        return isAdmin === 1 || isAdmin === 2 || isAdmin === 3 || isAdmin === 4 ? (
          rest.meta ? (
            <Can I={rest.meta.action} a={rest.meta.subject}>
              <Component {...props} />
            </Can>
          ) : (
              <Component {...props} />
            )
        ) : (
            <Redirect
              to={{
                pathname: "/",
                state: { from: props.location },
              }}
            />
          );
      }}
    />
  );
};

export default ManagerGuard;
