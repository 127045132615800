import { usePost } from './index';
import services from '../service';

export const useCreateContact = (successCallback, errorCallback) => {
    return usePost(services.Contact.createContact, successCallback, errorCallback);
};

export const useContacts = (repository) => {
  const [{data, loading, paginate}, handleSubmit] = usePost(repository.getContacts);
  return [{contacts: data, loading, paginate}, handleSubmit];
};