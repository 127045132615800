import * as types from "./../constants/actionType";

const initialState = {
  list: [],
};

const Favorite = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_FAVORITE:
      return {
        ...state,
        list: action.payload,
      };
    case types.ADD_PRODUCT_FAVORITE:
      return {
        list: [...state.list, action.payload],
      };
    case types.REMOVE_PRODUCT_FAVORITE:
      return {
        list: state.list.filter((item) => item.id !== action.payload.id),
      };

    default:
      return { ...state };
  }
};

export default Favorite;
