import React from 'react'
import { Col } from 'react-bootstrap'

const FavoriteItems = ({ product }) => {
    return (
        <Col xl={4} lg={4} md={6} sm={12}>
            {/* <div className="favorite_item">
                <img className="favorite_item_img" src={img} alt="" />
                <div className="favorite_item_body">
                    <div className="favorite_item_body_info">
                        <img src={avt} alt="" />
                        <strong>{name}</strong>
                        <div className="favorite_item_body_info_content d-flex justify-content-between">
                            <div>
                                <strong>Code Product</strong>
                                <p>{codeProduct}</p>
                            </div>
                            <div>
                                <strong>Acreage</strong>
                                <p>{acreage}</p>
                            </div>
                            <div>
                                <strong>Room number</strong>
                                <p>{roomNumber}</p>
                            </div>
                        </div>
                        <ul>
                            {
                                roomsDetails && roomsDetails.map((item, index) => (
                                    <li key={index.toString()}>
                                        {item}
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="favorite_item_body_btn">
                        <a href={"/compare/detail_product"} className="btn favorite_item_body_btn_custom">
                            View Detail
                        </a>
                    </div>
                </div>
            </div> */}
        </Col>
    )
}

export default FavoriteItems
