import axios from 'axios';

class BaseRepository {
  constructor(baseUrl) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Authorization": `Bearer ${window.localStorage.getItem('landcaster::token_user')}`
      },
      timeout: 10000
    });
  }

  /* Handle exception */
  processError = (err) => {
    throw err.response && err.response.data?.error;
  };
  responseBody = (res) => res.data;

  /* Update token in header of instance */
  setAuthorizeToken = token => {
    window.localStorage.setItem('landcaster::token_user', token);
    this.axiosInstance.defaults.headers.common[ "Authorization"] = `Bearer ${token}`;
  }

  /* Method GET */
  get = (url, params) => this.axiosInstance.get(url, { params: params })
    .then(this.responseBody)
    .catch(this.processError);

  /* Method POST */
  post = (url, params) => this.axiosInstance.post(url, params)
    .then(this.responseBody)
    .catch(this.processError)
}

export default BaseRepository;