import { postData, getDataByID, getAPI_BACKEND_URL } from '../utils/base_service'
import { GET_LIST_PRODUCT_PUBLIC, GET_LIST_PRODUCT_FAVORITE, LIKE_PRODUCT } from '../constants/Config'
import { destructServerMessage, destructServerErrors } from "../helpers/error";


// GET LIST CART
export const getListProduct = (search) => {
    return postData(getAPI_BACKEND_URL(GET_LIST_PRODUCT_PUBLIC), search)
        .then((res) => {
            return Promise.resolve({ data: res.data });
        })
        .catch((err) => {
            let msg = destructServerMessage(err);
            return Promise.resolve({ err: msg });
        });
};

// GET DETAIL CART
export const getDetailProduct = (id) => {
    return getDataByID(getAPI_BACKEND_URL(GET_LIST_PRODUCT_PUBLIC), id).then(res => {
        return Promise.resolve({ data: res.data });
    }).catch(err => {
        let msg = destructServerErrors(err);
        return Promise.reject({ err: msg });
    })
}

//GET FAVORITE

export const getListProductFavorite = (search) => {
    return postData(getAPI_BACKEND_URL(GET_LIST_PRODUCT_FAVORITE), search)
        .then((res) => {
            return Promise.resolve({ data: res.data })
        })
        .catch((err) => {
            let msg = destructServerMessage(err)
            return Promise.reject({ err: msg })
        })
}

//ADD FAVORITE
export const addProductFavorite = (id) => {
    return getDataByID(getAPI_BACKEND_URL(LIKE_PRODUCT), id)
        .then((res) => {
            return Promise.resolve({ data: res.data })
        })
        .catch((err) => {
            let msg = destructServerMessage(err)
            return Promise.reject({ err: msg })
        })
}