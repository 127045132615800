import React from 'react'
import img from '../../../assets/images/detail_product.png'
import "../../../assets/styles/manager/favorite.scss"
import avatar from '../../../assets/images/p1.svg'
import FavoriteItems from './FavoriteItems'
import { Container, Row } from 'react-bootstrap'


const data = [
    {
        id: 1,
        avt: avatar,
        img: img,
        name: "Lancaster Villas 1",
        codeProduct: "12345",
        acreage: "75m2",
        roomNumber: "5 rooms",
        roomsDetails: ["2 Bedroom", "2 Bathroom", "1 Power room"]
    },
    {
        id: 2,
        avt: avatar,
        img: img,
        name: "Lancaster Villas 2",
        codeProduct: "12345",
        acreage: "75m2",
        roomNumber: "5 rooms",
        roomsDetails: ["2 Bedroom", "2 Bathroom", "1 Power room"]
    },
    {
        id: 3,
        avt: avatar,
        img: img,
        name: "Lancaster Villas 3",
        codeProduct: "12345",
        acreage: "75m2",
        roomNumber: "5 rooms",
        roomsDetails: ["2 Bedroom", "2 Bathroom", "1 Power room"]
    },
    {
        id: 4,
        avt: avatar,
        img: img,
        name: "Lancaster Villas 4",
        codeProduct: "12345",
        acreage: "75m2",
        roomNumber: "5 rooms",
        roomsDetails: ["2 Bedroom", "2 Bathroom", "1 Power room"]
    },
    {
        id: 5,
        avt: avatar,
        img: img,
        name: "Lancaster Villas 5",
        codeProduct: "12345",
        acreage: "75m2",
        roomNumber: "5 rooms",
        roomsDetails: ["2 Bedroom", "2 Bathroom", "1 Power room"]
    },
    {
        id: 6,
        avt: avatar,
        img: img,
        name: "Lancaster Villas 6",
        codeProduct: "12345",
        acreage: "75m2",
        roomNumber: "5 rooms",
        roomsDetails: ["2 Bedroom", "2 Bathroom", "1 Power room"]
    }

]

const ProductView = () => {
    return (
        <>
            <section className="favorite_productview">
                <Container className="flex-start">
                    <Row>
                        {
                            data && data.map((item, index) => (
                                <FavoriteItems product={item} key={index.toString()} />
                            ))
                        }
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default ProductView
