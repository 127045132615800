import React from "react";
import { Switch, Route } from "react-router-dom";
import { Media } from "../container/manager/Media";

const MediaRoutes = () => {
  const routes = [
    {
      path: "/manager/media/:id?",
      render: (props) => <Media {...props} />,
      exact: true
    },
  ];

  return (
    <div>
      <Switch>
        {routes.map((item, index) => {
          return <Route {...item} key={index} />;
        })}
      </Switch>
    </div>
  );
};

export default MediaRoutes;
