import React, { useEffect } from 'react';
import { updateBaseUrl } from '../utils/base_service';

const switchUrl = (targetUrl, originUrl) => Component => ({ ...props }) => {
    /* Change to API base url */
    updateBaseUrl(targetUrl);

    /* Switch to default base url if component unmount */
    useEffect(() => {
        return function(){
        updateBaseUrl(originUrl);
        }
    }, []);

    return <Component {...props} />;
};

export default switchUrl;