import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Popover } from "react-bootstrap";
import i18n from "i18next";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import { setUser, logOutUser } from "../actions/user";
import logoHeader from "./../assets/images/logo-header-v2.png";
import nav from "./../assets/images/nav-menu.svg";
import Login from "./Login";
import Register from "./Register";
import MenuProfile from "./MenuProfile";

const Header = ({ user, setUser, logoutUser }) => {
  const { authenticated } = user;
  const [menu, setMenu] = useState(false);
  const [language, setLanguage] = useState("en");
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const { t } = useTranslation();
  const showNav = () => {
    setMenu(!menu);
    if (menu) {
      document.getElementById("nav-menu").style.marginTop = "-100vh";
      document.querySelector(
        ".header-nav-bg"
      ).style.transform = `rotate(90deg)`;
      if (!authenticated) {
        document.querySelector(".section-header-user").style.opacity = "1";
        document.querySelector(".section-header-user").style.pointerEvents =
          "all";
      }
    } else {
      document.getElementById("nav-menu").style.marginTop = "0";
      document.querySelector(".header-nav-bg").style.transform = `rotate(0deg)`;
      document.querySelector("#nav-menu ul").style.display = "block";
      if (!authenticated) {
        document.querySelector(".section-header-user").style.opacity = "0.3";
        document.querySelector(".section-header-user").style.pointerEvents =
          "none";
      }
    }
  };

  const openLogin = () => {
    document.querySelector(".wrap-login-form").style.cssText = "width: 100vw; height: 100vh; position: fixed; background: transparent; z-index: 900;";
    document.querySelector(".login-form").style.transform = `translateX(0)`;
    document.querySelector(
      ".section-header-bot-list"
    ).style.transform = `translateY(-100%)`;
    // if (!authenticated) {
    //   document.querySelector(
    //     ".section-header-user-login-hide p"
    //   ).style.transform = `translateX(0)`;
    //   document.querySelector(
    //     ".section-header-user-login-hide p"
    //   ).style.opacity = `1`;
    // }

    if (register) {
      document.querySelector(
        ".register-form"
      ).style.transform = `translateX(100%)`;
      setRegister(!register);
    }
  };
  const closeLogin = () => {
    document.querySelector(".wrap-login-form").style.cssText = "width: unset; height: unset; z-index: 900";
    document.querySelector(".login-form").style.transform = `translateX(100%)`;
    document.querySelector(
      ".section-header-bot-list"
    ).style.transform = `translateY(0)`;
    // if (!authenticated) {
    //   document.querySelector(
    //     ".section-header-user-login-hide p"
    //   ).style.transform = `translateX(100%)`;
    //   document.querySelector(
    //     ".section-header-user-login-hide p"
    //   ).style.opacity = `0`;
    // }
  };

  const showLogin = () => {
    if (login) {
      closeLogin();
    } else {
      openLogin();
    }
    setLogin(!login);
  };

  const showRegister = () => {
    setRegister(!register);
    if (register) {
      document.querySelector(
        ".register-form"
      ).style.transform = `translateX(100%)`;

      document.querySelector(
        ".section-header-bot-list"
      ).style.transform = `translateY(0)`;
    } else {
      document.querySelector(
        ".register-form"
      ).style.transform = `translateX(0)`;
      document.querySelector(
        ".section-header-bot-list"
      ).style.transform = `translateY(-100%)`;
      if (login) {
        document.querySelector(
          ".login-form"
        ).style.transform = `translateX(100%)`;
        // if (!authenticated) {
        //   document.querySelector(
        //     ".section-header-user-login-hide p"
        //   ).style.transform = `translateX(100%)`;
        //   document.querySelector(
        //     ".section-header-user-login-hide p"
        //   ).style.opacity = `0`;
        // }

        setLogin(!login);
      }
    }
  };

  const leftMenus = [
    {
      title: t("header.overview"),
      url: "/overview",
    },
    {
      title: t("header.project"),
      url: "/project",
    },
    {
      title: t("header.explore"),
      url: "/explore",
    },
    {
      title: t("header.location"),
      url: "/location",
    },
    {
      title: t("header.amenities"),
      url: "/amenities",
    },
  ];
  const rightMenus = [
    {
      title: t("header.floorplans"),
      url: "/floorplans",
    },
    {
      title: t("header.availability"),
      url: "/availability",
    },
    {
      title: t("header.team"),
      url: "/team",
    },
    {
      title: t("header.news"),
      url: "/news",
    },
    {
      title: t("header.contact"),
      url: "/contact",
    },
  ];

  const selectLanguage = () => {
    const elePopoverLg = document.getElementById("popover_language");
    elePopoverLg.classList.remove("show");
    setLanguage(language === "en" ? "vi" : "en");
    i18n.changeLanguage(i18n.language === "US" ? "VN" : "US");
    // i18n.changeLanguage(i18n.language === "VN" );
  };

  const authSuccess = (user) => {
    setUser(user);
    // CLose modal register login
    if (login) showLogin();
    if (register) showRegister();
  };

  const changeAuthUser = () => {
    showLogin();
    showRegister();
  };

  const onCloseLogin = () => {
    closeLogin();
    setLogin(false);
    if (register) {
      document.querySelector(
        ".register-form"
      ).style.transform = `translateX(100%)`;
      setRegister(!register);
    }
  }

  return (
    <>
      <Login
        loginSuccess={authSuccess}
        changeAuthUser={changeAuthUser}
        showRegister={showRegister}
        onCloseLogin={onCloseLogin}
      />
      <Register
        registerSuccess={authSuccess}
        changeAuthUser={changeAuthUser}
        showLogin={showLogin}
        onCloseLogin={onCloseLogin}
      />
      <section className="section-header">
        <div className="logo-header">
          <img src={logoHeader} alt="Logo" />
        </div>
        <div className="section-header-lang btn-hover-simple-text">
          <OverlayTrigger
            placement="bottom-end"
            rootClose
            trigger="click"
            key="language"
            overlay={
              <Popover id={`popover_language`} className="popoper_language">
                <Popover.Title>
                  {i18n.language === "US" ? "Language" : "Ngôn ngữ"}
                </Popover.Title>
                <Popover.Content>
                  <p onClick={selectLanguage} className="select_language_item">
                    {i18n.language === "US" ? "Vietnamese" : "English"}
                  </p>
                </Popover.Content>
              </Popover>
            }
          >
            <p>{i18n.language === "US" ? "EN" : "VN"}</p>
          </OverlayTrigger>
          {/* <p>VN</p> */}
        </div>

        <div className="section-header-user ">
          {authenticated ? (
            <MenuProfile user={user.data} logoutUser={logoutUser} />
          ) : (
            <>
              <div
                className="section-header-user-login d-flex "
                onClick={() => showLogin()}
              >
                {/* <span className="section-header-user-login-hide">
                    <p>{t("header.hide")}_</p>
                  </span> */}
                <span>
                  <p>{t("header.login")}</p>
                </span>
              </div>
              {/* <div
                className={
                  !register
                    ? "section-header-user-regis"
                    : "section-header-user-regis active"
                }
                onClick={() => showRegister()}
              >
                <span>{t("header.register")}</span>
                <div className="section-header-user-regis-liquid"></div>
              </div> */}
            </>
          )}
        </div>

        <div className="container-fluid">
          <div className="row section-header-top">
            <div className="header-nav">
              <img src={nav} onClick={showNav} alt="Toggle" />
              <div className="header-nav-bg"></div>
              <div className="header-nav-menu" id="nav-menu">
                <ul>
                  {leftMenus &&
                    leftMenus.map((menu, index) => (
                      <li key={index.toString()}>
                        <NavLink
                          to={menu.url}
                          activeClassName="list-active-nav"
                          onClick={showNav}
                        >
                          {menu.title}
                        </NavLink>
                      </li>
                    ))}
                  {rightMenus &&
                    rightMenus.map((menu, index) => (
                      <li key={index.toString()}>
                        <NavLink
                          to={menu.url}
                          activeClassName="list-active-nav"
                          onClick={showNav}
                        >
                          {menu.title}
                        </NavLink>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          {/* desktop */}
          <div className="row section-header-bot">
            <div className="section-header-bot-list">
              <ul className="list-left">
                {leftMenus &&
                  leftMenus.map((menu, index) => (
                    <li key={index.toString()}>
                      <NavLink to={menu.url} activeClassName="list-active">
                        {menu.title}
                      </NavLink>
                    </li>
                  ))}
              </ul>

              <ul className="list-right">
                {rightMenus &&
                  rightMenus.map((menu, index) => (
                    <li key={index.toString()}>
                      <NavLink to={menu.url} activeClassName="list-active">
                        {menu.title}
                      </NavLink>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setUser: (user) => dispatch(setUser(user)),
    logoutUser: () => dispatch(logOutUser()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
