import React from "react";
import { NavLink } from "react-router-dom";
import Can from "../service/Can";
import store from '../store/store'
import {useTranslation} from 'react-i18next'

import "../assets/styles/common/menuManager.scss";

const CheckRole = () => {
  const { user } = store.getState()
  const isRole = user.data && user.data.position_id
  return isRole === 1 ? (
    <li>
      <NavLink
        to="/manager/sale_list"
        activeClassName="active"
        className="manager_menu_list_navLink"
      >
        <p className="text-capitalize">Sales</p>
      </NavLink>
    </li>
  ) : null
}
  

const MenuManager = () => {
  const { user } = store.getState()
  const isRole = user.data && user.data.position_id
  const {t} = useTranslation()

  return (
    <section className="manager_menu">
      <div className="container">
        <div className="">
          <ul
            id="list-menu"
            className="manager_menu_list d-flex justify-content-between"
          >
            <li>
              {/* <Can I="customer.list" a="customers"> */}
              <NavLink
                to="/manager/dashboard"
                activeClassName="active"
                className="manager_menu_list_navLink"
              >
                <p className="text-capitalize">{t("menu.dashboard")}</p>
              </NavLink>
              {/* </Can> */}
            </li>
            <li>
              {/* <Can I="customer.list" a="customers"> */}
              <NavLink
                to="/manager/cart"
                activeClassName="active"
                className="manager_menu_list_navLink"
              >
                <p className="text-capitalize">{t("menu.cart")}</p>
              </NavLink>
              {/* </Can> */}
            </li>
            <li>
              <NavLink
                to="/manager/media"
                activeClassName="active"
                className="manager_menu_list_navLink"
              >
                <p className="text-capitalize">{t("menu.media")}</p>
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/manager/customer"
                activeClassName="active"
                className="manager_menu_list_navLink"
              >
                <p className="text-capitalize">{t("menu.info")}</p>
              </NavLink>
            </li>
        
            {/* <li>
              <Can I="sale.list" a="sales">
              <NavLink
                to="/manager/Order"
                activeClassName="active"
                className="manager_menu_list_navLink"
              >
                <p className="text-capitalize">Order</p>
              </NavLink>
              </Can>
            </li> */}
            {/* <Can I="customer.list" a="customers"> */}
            <CheckRole />
            {/* </Can> */}

            {
              isRole === 1 ? (<></>) : (
                <li>
                  <NavLink
                    to="/manager/contacts"
                    activeClassName="active"
                    className="manager_menu_list_navLink"
                  >
                    <p className="text-capitalize">{t("menu.contact")}</p>
                  </NavLink>
                </li>
              )
            }
          </ul>
        </div>
      </div>
    </section>
  );
};

export default MenuManager;
