import React, { useState } from "react";
import HeaderFavorite from './HeaderFavorite'
import ListView from './ListView'
import ProductView from './ProductView'

const MainAvailability = (props) => {
    const { } = props;

    const [menu, setMenu] = useState(1);

    const handleSetMenu = (n) => {
        setMenu(n);
    };
    return (
        <>
            <div className="favorite-manager-main">
                <HeaderFavorite
                    handleSetMenu={(n) => handleSetMenu(n)}
                />
                {menu === 1 ? <ProductView /> : <ListView />}
            </div>
            <div></div>
        </>
    );
};

export default MainAvailability;
