import React, { memo, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Form, Input, Button, Row, Col, DatePicker, Select } from "antd";
import { CUSTOMER_STATUSES } from "../../../constants";
import { transformErrors } from "../../../utils";
import { NotificationManager } from "react-notifications";
import { useCustomerData, useUpdateCustomer } from "../../../hooks/customer";

const DetailCustomer = ({ data, onSuccess, ...props }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const customer = useCustomerData(data);
  const successCallback = (res) => {
    onSuccess();
    NotificationManager.success(
      t("customer.update.message"),
      t("customer.update.title")
    );
  };
  const failedCallback = (errors) => {
    let messages = transformErrors(errors, "errors");
    form.setFields(messages);
  };
  const [{ loading }, handleSubmit] = useUpdateCustomer(
    successCallback,
    failedCallback
  );

  const onFinish = (values) => {
    values.birthday = values.birthday && values.birthday.format("YYYY-MM-DD");
    handleSubmit(data.id, values);
  };

  useEffect(() => {
    form.setFieldsValue(customer);
  }, [customer]);

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="modal_header">
        <span className="modal_header_title">{t("customer.detail")}</span>
        <span onClick={props.onHide}>x</span>
      </div>
      <Form
        form={form}
        onFinish={onFinish}
        initialValues={customer}
        className="ant-form-custom"
      >
        <div className="modal_body">
          <div className="container">
            <Row gutter={15}>
              <Col span={12}>
                <Form.Item name="name" label={t("customer.attributes.name")}>
                  <Input placeholder={t("customer.attributes.name")} />
                </Form.Item>

                <Form.Item name="phone" label={t("customer.attributes.phone")}>
                  <Input placeholder={t("customer.attributes.phone")} />
                </Form.Item>

                <Form.Item
                  name="birthday"
                  label={t("customer.attributes.birthday")}
                >
                  <DatePicker
                    placeholder={t("customer.attributes.birthday")}
                    format="DD/MM/YYYY"
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="address"
                  label={t("customer.attributes.address")}
                >
                  <Input placeholder={t("customer.attributes.address")} />
                </Form.Item>

                <Form.Item name="email" label={t("customer.attributes.email")}>
                  <Input placeholder={t("customer.attributes.email")} />
                </Form.Item>

                {/* <Form.Item name="home_town" label={t('customer.attributes.home_town')}>
                  <Input placeholder={t('customer.attributes.home_town')} />
                </Form.Item> */}

                <Form.Item
                  name="status"
                  label={t("customer.attributes.status")}
                >
                  <Select
                    options={CUSTOMER_STATUSES}
                    placeholder={t("customer.attributes.status")}
                  ></Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="note" label={t("customer.attributes.note")}>
                  <Input.TextArea
                    rows={4}
                    placeholder={t("customer.attributes.note")}
                  ></Input.TextArea>
                </Form.Item>
              </Col>
            </Row>
            <table className="table-details-customer">
              <thead>
                <tr>
                  <th>Tên sản phẩm</th>
                  <th> Trạng thái </th>
                  <th>Hành động</th>
                </tr>
              </thead>
              <tbody>
                {data && data.product ? (
                  data.product.map((item, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{item.product_code}</td>
                        <td>{item.status_lable}</td>
                        <td>Xem chi tiết</td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan="3">no data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        {!props.onlyRead && (
          <div className="modal_footer row justify-content-center">
            <button onClick={props.onHide} htmlType="button" disabled={loading}>
              {t("buttons.cancel")}
            </button>
            <Button loading={loading} htmlType="submit">
              {t("buttons.update")}
            </Button>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default DetailCustomer;
