import * as TYPE from "../constants/actionType";

const defaultState = {
  isLoading: true,
  data: null,
  authenticated: false,
};

const user = (state = defaultState, action) => {
  switch (action.type) {
    case TYPE.SET_USER: {
      return {
        data: action.payload,
        isLoading: false,
        authenticated: action.payload ? true : false,
      };
    }
    case TYPE.SET_LOADING_USER: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    case TYPE.LOG_OUT: {
      return defaultState;
    }
    default:
      return state;
  }
};

export default user;
