import React from 'react';
import { transformErrors } from '../../../utils';
import { Select, Form, Input, Button } from 'antd';
import { CUSTOMER_STATUSES } from '../../../constants';
import { useCreateCustomer } from '../../../hooks/customer';
import switchUrl from '../../../hocs/switchUrl';
import { API_URL, BASE_URL } from '../../../constants';
import { NotificationManager } from 'react-notifications';
import { useTranslation } from 'react-i18next';

const FormUser = ({ onCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const onCancelForm = () => {
    onCancel();
    form.resetFields();
  }

  const failedCallback = (errors) => {
    let messages = transformErrors(errors, 'errors');
    form.setFields(messages);
  }

  const successCallback = (res) => {
    onCancelForm();
    onSuccess(res);
    NotificationManager.success(t('customer.create.message'), t('customer.create.title'));
  }

  const [{loading}, handleSubmit] = useCreateCustomer(successCallback, failedCallback)

  const onFinish = values => {
    handleSubmit(values);
  }

  return (
    <Form form={form} layout='horizontal' onFinish={onFinish} className="justify-content-center">
      <p className="mb-1 text-success">{t('customer.create.header')}</p>
      <Form.Item name="name" label={t('customer.attributes.name')} labelCol={{span: 24}}>
        <Input placeholder={t('customer.attributes.name')} />
      </Form.Item>

      <Form.Item name="phone" label={t('customer.attributes.phone')} labelCol={{span: 24}}>
        <Input placeholder={t('customer.attributes.phone')} />
      </Form.Item>

      <Form.Item name="email" label={t('customer.attributes.email')} labelCol={{span: 24}}>
        <Input placeholder={t('customer.attributes.email')} />
      </Form.Item>

      <Form.Item name="status" label={t('customer.attributes.status')} labelCol={{span: 24}}>
        <Select options={CUSTOMER_STATUSES} placeholder={t('customer.attributes.status')}></Select>
      </Form.Item>

      <div className="btn-groups mt-2">
        <Button danger type="primary mr-2" htmlType="button" onClick={onCancelForm} disabled={loading}>
          {t('buttons.cancel')}
        </Button>
        <Button type="primary" htmlType="submit" loading={loading}>
          {t('buttons.create')}
        </Button>
      </div>
    </Form>
  );
}

export default switchUrl(API_URL, BASE_URL)(FormUser);