import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { AUTH_ROLE } from "../constants";
import {
  Users,
  LogOut,
  File,
  Send,
  Heart,
  ShoppingCart,
  Database,
  User,
} from "react-feather";
import cx from "classnames";
import { checkIsAdmin } from "../helpers/functions";

import "../assets/styles/common/menu_profile.scss";
import { useTranslation } from "react-i18next";

const MenuProfile = ({ user, logoutUser }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [menu, setMenu] = useState({
    visible: false,
    first: true,
  });
  const { avatar, name, role, position_id } = user;
  const avatarUrl = process.env.REACT_APP_ENDPOINT_BACKEND + avatar;
  const listMenuGuard = [
    {
      id: 1,
      name: t("guard_menu.dashboard"),
      url: "/dashboard",
      rule: [AUTH_ROLE.SALE],
      icon: <Database />,
    },
    {
      id: 2,
      name: t("guard_menu.customer"),
      url: "/customer",
      rule: [AUTH_ROLE.SALE],
      icon: <Users />,
    },
    {
      id: 3,
      name: t("guard_menu.media"),
      url: "/media",
      rule: [AUTH_ROLE.SALE],
      icon: <File />,
    },
    {
      id: 4,
      name: t("guard_menu.order"),
      url: "/order",
      rule: [AUTH_ROLE.SALE],
      icon: <Send />,
    },
    {
      id: 5,
      name: t("guard_menu.cart"),
      url: "/cart",
      rule: [],
      icon: <ShoppingCart />,
    },
    {
      id: 6,
      name: t("guard_menu.favorite"),
      url: "/favorite",
      rule: [],
      icon: <ShoppingCart />,
    },
  ];

  const listMenuSale = [
    {
      id: 1,
      name: t("sale_menu.dashboard"),
      url: "/dashboard",
      rule: [AUTH_ROLE.SALE],
      icon: <Database />,
    },
    {
      id: 2,
      name: t("sale_menu.customer"),
      url: "/customer",
      rule: [AUTH_ROLE.SALE],
      icon: <Users />,
    },
    {
      id: 3,
      name: t("sale_menu.media"),
      url: "/media",
      rule: [AUTH_ROLE.SALE],
      icon: <File />,
    },
    // {
    //   id: 4,
    //   name: "Quản lý Order",
    //   url: "/order",
    //   rule: [AUTH_ROLE.SALE],
    //   icon: <Send />,
    // },
    {
      id: 5,
      name: t("sale_menu.cart"),
      url: "/cart",
      rule: [],
      icon: <ShoppingCart />,
    },
  ];
  const listMenuDVPP = [
    {
      id: 1,
      name: t("distributor_menu.dashboard"),
      url: "/dashboard",
      rule: [AUTH_ROLE.SALE],
      icon: <Database />,
    },
    {
      id: 2,
      name: t("distributor_menu.customer"),
      url: "/customer",
      rule: [AUTH_ROLE.SALE],
      icon: <Users />,
    },
    {
      id: 3,
      name: t("distributor_menu.media"),
      url: "/media",
      rule: [AUTH_ROLE.SALE],
      icon: <File />,
    },
    // {
    //   id: 4,
    //   name: "Quản lý Order",
    //   url: "/order",
    //   rule: [AUTH_ROLE.SALE],
    //   icon: <Send />,
    // },
    {
      id: 5,
      name: t("distributor_menu.cart"),
      url: "/cart",
      rule: [],
      icon: <ShoppingCart />,
    },
    {
      id: 6,
      name: t("distributor_menu.sale_list"),
      url: "/sale_list",
      rule: [AUTH_ROLE.SALE],
      icon: <Users />,
    },
  ];

  const handleMenu = (e) => {
    // if (e) e.stopPropagation();
    // const eleOptionsMenu = document.getElementById("menu_profile_options");
    // if (menu.visible) {
    //   setTimeout(() => {
    //     eleOptionsMenu.style.display = "none";
    //   }, 500);
    // } else {
    //   eleOptionsMenu.style.display = "initial";
    // }
    // setMenu({ visible: !menu.visible, first: false });
  };

  const logout = () => {
    logoutUser();
  };
  const redirectManager = (url) => {
    handleMenu();
    history.push("/manager" + url);
  };
  const redirectUser = (url) => {
    handleMenu();
    history.push(url);
  };

  const classOpenMenu = menu.visible
    ? "menu_open fade-in-down"
    : menu.first
    ? ""
    : "fade-in-up-none";

  const Menu = () => {
    if (position_id == 2) {
      return (
        <>
          {listMenuSale.map((menu) => {
            return (
              <li key={menu.id} onClick={() => redirectManager(menu.url)}>
                {menu.icon} {menu.name}
              </li>
            );
          })}
        </>
      );
    }
    if (position_id == 1) {
      return (
        <>
          {listMenuDVPP.map((menu) => {
            return (
              <li key={menu.id} onClick={() => redirectManager(menu.url)}>
                {menu.icon} {menu.name}
              </li>
            );
          })}
        </>
      );
    }
    return "";
  };
  // console.log(position_id)
  return (
    <div className="menu_profile" onMouseLeave={handleMenu}>
      <div onMouseEnter={handleMenu} className="d-flex align-items-center">
        <div className="menu_profile--avatar_wrapper">
          <img alt="avatar" src={avatarUrl} />
        </div>
      </div>
      <div
        className={cx("menu_profile--profile_options")}
        id="menu_profile_options"
      >
        <ul>
          <li onClick={() => redirectUser("/account")}>
            <User /> {t("account.my_account")}
          </li>
          <li onClick={() => redirectUser("/favorite")}>
            <Heart /> {t("account.my_favorite")}
          </li>

          <Menu />

          <li onClick={logout}>
            <LogOut /> {t("account.logout")}{" "}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MenuProfile;
