import React from 'react'
import MainOrder from './MainOrder'
const Order = () => {
    return (
        <>
            <MainOrder />
        </>
    )
}

export default Order
