import { usePost, usePut } from './index';
import services from '../service';

export const useCustomer = () => {
  const [{data, loading, paginate}, handleSubmit] = usePost(services.Customer.getCustomer);
  return [{customers: data, loading, paginate}, handleSubmit];
};

export const useCreateCustomer = (successCallback, failedCallback) => {
  return usePost(services.Customer.create, successCallback, failedCallback);
};


export const useUpdateCustomer = (successCallback, failedCallback) => {
  return usePut(services.Customer.update, successCallback, failedCallback);
};

export const useDevideCustomer = (successCallback, failedCallback) => {
  const [{data, loading}, handleSubmit] = usePost(services.Customer.devideCustomerSale, successCallback, failedCallback);
  return [{formLoading: loading}, handleSubmit];
};

export const useCustomerData = (data = {}) => {
  const { name, phone, email, address, status, note } = data;
  return { name, phone, email, address, status, note };
}