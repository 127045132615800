import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import HomepageRoutes from "../routes/homepage.route.js";
import ManagerRoutes from "../routes/manager.route";
import { postData, getAPI_BACKEND_URL } from "../utils/base_service";
import { initUserFromToken } from "../actions/user";
import { GET_LIST_PRODUCT_FAVORITE } from "../constants/Config";
import { setListProductFavorite } from "../actions/favorite.js";
import Loading from "../assets/images/loading.gif";

import "react-multi-carousel/lib/styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

const App = ({ checkAuthUser, user, setListProductFavorite }) => {
  const { data, isLoading } = user;

  const getDataFavorite = () => {
    postData(getAPI_BACKEND_URL(GET_LIST_PRODUCT_FAVORITE))
      .then((res) => {
        setListProductFavorite(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    let mounted = true;
    getDataFavorite();
    if (mounted && !data) checkAuthUser();
    return () => {
      mounted = false;
    };
  }, []);

  if (isLoading) {
    return (
      <div className="main-loading">
        <img src={Loading} alt="loading..." />
      </div>
    );
  }
  return (
    <Router>
      <Switch>
        <Route exact path="/manager*" render={() => <ManagerRoutes />} />
        <Route exact path="/*" component={HomepageRoutes}></Route>
      </Switch>
    </Router>
  );
};
const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    checkAuthUser: () => dispatch(initUserFromToken()),
    setListProductFavorite: (data) => dispatch(setListProductFavorite(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
