import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import { postData, getAPI_BACKEND_URL } from "../utils/base_service";
import { API_REGISTER } from "../constants";
import TextError from "../components/TextError";
import Mail from '../assets/images/icons/mail.svg'
import User from '../assets/images/icons/user.svg'
import Password from '../assets/images/icons/key.svg'

const Register = ({ registerSuccess, changeAuthUser, showLogin, onCloseLogin }) => {
  const { t } = useTranslation();
  const [register, setRegister] = useState({
    loading: false,
    error: null,
  });

  const registeSchemaValidate = yup.object().shape({
    name: yup.string().required(t("form.error_name")).min(2),
    email: yup
      .string()
      .email(t("form.errorEmail"))
      .required(t("form.errorRequired", { name: "Email" })),
    password: yup
      .string()
      .min(6, t("form.errorPassLeast", { min: 6 }))
      .required(t("form.errorRequired", { name: t("common.password") })),
    password_confirm: yup
      .string()
      .oneOf([yup.ref("password"), null], t("form.error_confirm_password")),
  });

  const submitRegister = (values) => {
    const { email, password, remember_me, name } = values;
    setRegister({
      ...register,
      loading: true,
    });
    postData(getAPI_BACKEND_URL(API_REGISTER), { name, email, password })
      .then((res) => {
        const { data } = res.data;
        registerSuccess({ ...data, remember_login: remember_me });
        setRegister({
          loading: false,
          error: null,
        });
      })
      .catch(({ errors }) => {
        if (errors.email) {
          setRegister({
            loading: false,
            error: errors.email[0],
          });
        }
      });
  };

  return (
    <>
      <div className="wrap-login-form" onClick={onCloseLogin}></div>
      <div className="register-form">
        <div className="form-body">
          <h1 className="title">{t("header.register")}</h1>
          <p className="title description">{t("common.desc_register")}</p>
          <Formik
            initialValues={{
              name: "",
              email: "",
              password: "",
              password_confirm: "",
              remember_me: false,
            }}
            validationSchema={registeSchemaValidate}
            onSubmit={submitRegister}
          >
            {({ errors, touched, isValid, dirty }) => (
              <Form>
                <div className="form-group">
                  <div className="form-group_custom">
                    <img className="form-group_icon-user" src={User} />
                    <Field
                      type="text"
                      name="name"
                      placeholder={t("form.name")}
                      className="form-control form-input form-input-email"
                    />
                  </div>
                  {errors.name && touched.name && (
                    <TextError message={errors.name} />
                  )}
                </div>
                <div className="form-group">
                  <div className="form-group_custom">
                    <img className="form-group_icon-mail" src={Mail} />
                    <Field
                      type="text"
                      name="email"
                      placeholder={t("common.email")}
                      className="form-control form-input form-input-email"
                    />
                  </div>
                  {errors.email && touched.email && (
                    <TextError message={errors.email} />
                  )}
                </div>
                <div className="form-group">
                  <div className="form-group_custom">
                    <img className="form-group_icon-password" src={Password} />
                    <Field
                      name="password"
                      type="password"
                      placeholder={t("common.password")}
                      className="form-control form-input form-input-password"
                    />
                  </div>
                  {errors.password && touched.password && (
                    <TextError message={errors.password} />
                  )}
                </div>
                <div className="form-group">
                  <div className="form-group_custom">
                    <img className="form-group_icon-password" src={Password} />
                    <Field
                      name="password_confirm"
                      type="password"
                      placeholder={t("form.confirm_password")}
                      className="form-control form-input form-input-password"
                    />
                  </div>
                  {errors.password_confirm && touched.password_confirm && (
                    <TextError message={errors.password_confirm} />
                  )}

                  <div className="form-link-block">
                    <div className="checkbox-form">
                      <Field
                        type="checkbox"
                        id="remember2"
                        name="remember_me"
                      />
                      <label htmlFor="remember2">
                        {t("common.remember_me")}
                      </label>
                    </div>
                  </div>
                </div>
                {register.error && <TextError message={register.error} />}
                <p className="auth_redireact_text">
                  {t("form.haveAccount")}
                  <span onClick={() => changeAuthUser()}> {t("form.signInNow")}</span>
                </p>
                <div className="form-button">
                  <button
                    className="btn btn-custom btn-hover-black-neon"
                    style={{ width: `100%` }}
                    disabled={!(isValid && dirty) || register.loading}
                    type="submit"
                  >
                    {!register.loading ? (
                      t("header.register")
                    ) : (
                        <Spinner animation="border" />
                      )}
                  </button>
                  <div
                    className="btn btn-custom btn-hover-black-neon"
                    style={{ width: `100%` }}
                    onClick={() => showLogin()}
                  >
                    {t("header.login")}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default Register;
