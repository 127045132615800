import React from 'react'
import HeaderCustomer from './HeaderCustomer'
import ListView from './ListView'
import TableCustomer from './TableCustomer'

const MainCustomer = () => {
    return (
        <>
            {/* <HeaderCustomer /> */}
            <TableCustomer />
        </>
    )
}

export default MainCustomer
