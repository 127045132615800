import React from 'react'
import MainCustomer from './MainCustomer'
import Distribution from './Distribution'
import { Route, Switch } from 'react-router-dom';
import Can from '../../../service/Can';

const Customer = () => {
  return (
    <>
      <Switch>
        <Route exact path="/manager/customer" component={MainCustomer} />
        <Can I="sale.list" a="sales">
          <Route exact path="/manager/customer/distribution" component={Distribution} />
        </Can>
      </Switch>
    </>
  )
}

export default Customer
