import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import TableData from "../../../components/TableData";

const MockData = [
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "123234",
    Status: "Inprogress",
  },
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "123234",
    Status: "Reservations",
    Action: "Detail",
  },
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "123234",
    Status: "Reservations",
    Action: "Detail",
  },
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "123234",
    Status: "Finish",
    Action: "Detail",
  },
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "123234",
    Status: "Inprogress",
    Action: "Detail",
  },
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "123234",
    Status: "Inprogress",
    Action: "Detail",
  },
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "123234",
    Status: "Inprogress",
    Action: "Detail",
  },
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "123234",
    Status: "Inprogress",
    Action: "Detail",
  },
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "0909 090 090",
    Status: "Finish",
    Action: "Detail",
  },
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "0909 090 090",
    Status: "Inprogress",
    Action: "Detail",
  },
  {
    CodeOrder: "0123456789",
    Name: "Nguyen Van A",
    CodeProduct: "123234",
    Status: "Reservations",
    Action: "Detail",
  },
];

const ListOrder = () => {
  //   const history = useHistory();
  const [loading, setLoading] = useState(true);

  const base_column = [
    { title: "Code Orders", dataIndex: "CodeOrder" },
    { title: "Name", dataIndex: "Name" },
    { title: "Code Product", dataIndex: "CodeProduct" },
    { title: "Status", dataIndex: "Status" },
    {
      title: "Action",
      dataIndex: "Action",
      render: (_, data) => {
        return (
          <div>
            <button
              type="button"
              onClick={() => {
                alert("Chuc nang dang duoc cap nhap!");
                // history.push("/order/detail");
              }}
              className="btn btn-custom text-white"
            >
              Detail
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const mockTimeOut = () => setTimeout(() => setLoading(false), 300);
    const runMockTimeOut = mockTimeOut();
    return () => {
      clearTimeout(runMockTimeOut);
    };
  }, []);
  return (
    <section className="order_listviewr">
      <div className="container">
        <TableData data={MockData} loading={loading} columns={base_column} />
      </div>
    </section>
  );
};
export default ListOrder;
