import React, { Component, useState } from "react";
import { PER_PAGE } from "../../../constants/Variable";
import Table from "../../../components/common/Table";
import { getTakenData } from "../../../utils/base_service";
import { LIST_DISTRIBUTOR } from "../../../constants/Config";
import {
  getCustomerRequest,
  getDetailCustomerRequest,
} from "../../../actions/customer";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import InputSearch from "../../../components/common/InputSearch";
import Paginate from "../../../components/Paginate/index";
import { Modal, Button } from "react-bootstrap";
import AddCustomer from "./AddCustomer";
import DetailCustomer from "./DetailCustomer";
import NewButton from "../../../components/common/NewButton";
import TableData from "../../../components/TableData";
import Can from '../../../service/Can';

class TableCustomer extends Component {
  constructor(props) {
    super(props);
    this.search = "";
    this.message = null;
    this.state = {
      dataDetailsTableModal: {},
      ModalStatus: false,
      ModalDetail: false,
      openBlock: false,
      product_id: null,
      listDistributor: [],
      perPage: PER_PAGE,
      page: 0,
      paginate: [],
      tableHeaders: [
        {
          title: "Tên khách hàng",
          dataIndex: "name",
          render: (_, data) => {
            return <p>{data && data.fullname}</p>;
          },
        },
        {
          title: "Sản phẩm ",
          dataIndex: "email",
          render: (_, data) => {
            return (
              <p>
                {data &&
                  data.product.map((item, index) => {
                    return (
                      <Link to={`/product/${item.id}/detail`}>{`${item.product_code}${index !== data?.product?.length - 1 ? ", " : ""}`}</Link>
                    );
                  })}
              </p>
            );
          },
        },
        {
          title: "Số điện thoại",
          dataIndex: "phone",
          render: (_, data) => {
            return <p>{data && data.phone}</p>;
          },
        },
        {
          title: "Trạng thái",
          dataIndex: "status_lable",
          render: (_, data) => {
            return (
              <div
                className={`customer_status text-white status-${data.status}`}
              >
                {data.status_lable}
              </div>
            );
          },
        },
        {
          title: "Ghi chú",
          dataIndex: "note",
          render: (_, data) => {
            return <p>{data && data.note}</p>;
          },
        },
        {
          title: "Hành động",
          dataIndex: "action",
          render: (_, data) => {
            if (data.id) {
              return (
                <button
                  className="btn-custom-update"
                  onClick={() => this.showModalDetail(data)}
                >
                  Chi tiết
                </button>
              );
            }
          },
        },
      ],
    };
  }

  componentDidMount() {
    this.loadCustomer();
    this.setState({});
  }

  loadCustomer = async () => {
    let { page, perPage } = this.state;
    await this.props.getCustomer(this.search, { page, per_page: perPage });
  };

  changePage = (pageNumber, e) => {
    let { page, perPage } = this.state;
    if (pageNumber != page) {
      this.props.getCustomer(this.search, {
        page: pageNumber,
        per_page: perPage,
      });
      this.setState({ page: pageNumber });
    }
  };

  showModal = () => {
    this.setState({ ModalStatus: true });
  };

  showModalDetail = (data) => {
    this.setState({ ModalDetail: true, dataDetailsTableModal: data });
  };

  closeModal = () => {
    this.setState({ ModalStatus: false });
  };

  closeModalDetail = () => {
    this.setState({ ModalDetail: false });
  };

  onChangeSearch = (e) => {
    let { page, perPage } = this.state;
    let target = e.target;
    let name = target.name;
    let value = target.value ? target.value : "";

    this.search = {
      ...this.search,
      [name]: value,
    };

    this.props.getCustomer(this.search, { page: page, per_page: perPage });
  };

  onAddSuccess = () => {
    this.closeModal();
    this.loadCustomer();
  }

  onUpdateSuccess = () => {
    this.closeModalDetail();
    this.loadCustomer();
  }

  render() {
    let { customer } = this.props;
    let { dataDetailsTableModal } = this.state;
    const { isLoading } = customer;
    let tableData = customer && customer.data;
    let paginate = customer && customer.paginate;
    let { tableHeaders, ModalStatus, ModalDetail } = this.state;
    return (
      <div className="container table_customer mt-5">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <InputSearch onChange={this.onChangeSearch} />
          <div className="d-flex">
            <Can I="sale.list" a="sales">
              <Link to="/manager/customer/distribution" className="btn btn-success btn-large">Chia khách hàng</Link>
            </Can>
            <NewButton onClick={this.showModal} />
            <AddCustomer show={ModalStatus} onHide={this.closeModal} onSuccess={this.onAddSuccess} />
            <DetailCustomer data={dataDetailsTableModal} show={ModalDetail} onHide={this.closeModalDetail} onSuccess={this.onUpdateSuccess} />
          </div>
        </div>
        <TableData
          loading={isLoading}
          data={tableData}
          columns={tableHeaders}
          rowKey={(record) => record.id}
          pagination={{
            pageSizeOptions: [5, 10, 25, 50],
            showSizeChanger: false,
            pageSize: paginate.per_page,
            current: paginate.page,
            total: paginate.total,
            onChange: this.changePage,
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {
    customer: state.Customer,
    isLoading: state.Customer.isLoading,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    getCustomer: (data, params) => dispatch(getCustomerRequest(data, params)),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TableCustomer));
