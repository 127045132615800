import React, { Component } from "react";
import { Switch } from "react-router-dom";
import ManagerGuard from "./ManagerGuard";
import Hompage from "../container/manager/HomePage";
import MediaRoutes from "./media.route";
import FavoriteRoutes from "./favorite.route";
import "react-notifications/lib/notifications.css";
import { NotificationContainer } from "react-notifications";
import MenuManager from "../components/MenuManager";
import Header from "../components/Header";
import CustomerRoutes from "./customer.route";
import CartRoutes from "./cart.route";
import OrderRoutes from "./order.route";
import DashBoardRoutes from "./dashboard.route";
import SaleRoute from "./sale.route";
import ContactRoute from "./contact.route";
// import CustomerRoutes from "./customer.route";
// import SaleRoutes from "./sale.route";

export const routes = [
  // Home page
  {
    path: "/manager",
    component: () => <Hompage />,
    exact: true,
  },
  // Contact
  {
    path: "/manager/contacts",
    component: () => <ContactRoute />,
    exact: true,
  },
  // Media
  {
    path: "/manager/media/:id",
    component: () => <MediaRoutes />,
    exact: true,
  },
  {
    path: "/manager/media",
    component: () => <MediaRoutes />,
    exact: true,
  },
  // Favorite
  {
    path: "/manager/favorite",
    component: () => <FavoriteRoutes />,
    exact: true,
  },

  //Customer
  {
    path: "/manager/customer*",
    component: () => <CustomerRoutes />,
    exact: true,
  },
  //Cart
  {
    path: "/manager/cart*",
    component: () => <CartRoutes />,
    exact: true,
  },

  // Order
  {
    path: "/manager/order",
    component: () => <OrderRoutes />,
    exact: true,
  },

  {
    path: "/manager/dashboard",
    component: () => <DashBoardRoutes />,
    exact: true,
  },
  {
    path: "/manager/sale_list*",
    component: () => <SaleRoute />,
    exact: true,
  },
];

class ManagerRoutes extends Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <React.Fragment>
          <NotificationContainer />
          <Header />
          <MenuManager />
          <div
            className="page-wrapper"
            id="page-wrapper"
          // style={{ marginLeft: 280 }}
          >
            <Switch>
              {routes.map((route) => (
                <ManagerGuard key={route.path} {...route} />
              ))}
            </Switch>
          </div>
        </React.Fragment>
      );
    }
  }
}

export default ManagerRoutes;
