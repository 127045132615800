import React from 'react'


const Loader = (props) => {
  const { className } = props
  return (
    <>
      <div className={`loader ${className ? className : ''}`}>
        <div className="loader-inner">
          <div className="loader-line-wrap">
            <div clasclassNames="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
        </div>
      </div>
    </>
  )

}

export default Loader
