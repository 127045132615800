import React from 'react';
import { Form, Button, Input } from 'antd';

const FilterCustomer = ({ t, loading, handleSubmit }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    handleSubmit(values);
  }

  const onReset = () => {
    form.resetFields();
    const values = form.getFieldsValue();
    handleSubmit(values);
  }
  
  return (
    <Form form={form} layout='inline' onFinish={onFinish} className="justify-content-center my-3">
      <Form.Item name="search_text">
        <Input placeholder={t('product.placeholders.search')} prefix={<span className="lnr lnr-magnifier"></span>} />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" className="mr-2" loading={loading}>{t('buttons.search')}</Button>
        <Button danger htmlType="button" disabled={loading} onClick={onReset}>{t('buttons.reset')}</Button>
      </Form.Item>
    </Form>
  );
}

export default FilterCustomer;