import requester from "./requester";
import { CREATE_CONTACT_URL, GET_CONTACT_URL } from "../constants/Config";

export default {
  createContact: (params = {}) => requester.post(`${process.env.REACT_APP_ENDPOINT_API_BACKEND}/${CREATE_CONTACT_URL}`, params),
  getContacts: (params) =>
    requester.get(
      `${process.env.REACT_APP_ENDPOINT_API_BACKEND}/${GET_CONTACT_URL}`,
      params
    ),
};
